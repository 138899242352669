/* eslint-disable brace-style */
/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, Button } from 'antd'
import Api from 'app/modules/Api'
import swal from 'sweetalert'

export default class FreezeCustomers extends Component {
  constructor() {
    super(...arguments)

    this.state = {
      customers: [],
      submitting: false
    }

    this.freezeCustomers = this.freezeCustomers.bind(this)
    this.handleChangeCustomers = this.handleChangeCustomers.bind(this)
  }

  async freezeCustomers(event) {
    event.preventDefault()
    this.setState({ submitting: true })

    try {
      const emails = this.state.customers
      const response = await Api.get(`/admin/customers/email?emails=${emails}`)
      const { customers } = response

      const verificationResult = emails.map(email =>
        customers.some(customer => customer.email === email) || email
      )

      const invalidEmail = verificationResult.find(email => email !== true)
      if (invalidEmail) {
        swal('Erro!', `Email ${invalidEmail} não encontrado! `, 'error')
        return
      }

      await Promise.all(customers.map(async customer => {
        const oldData = await Api.get(`/admin/customer/${customer.customer_id}`)
        const customer_plan_data = {
          ...oldData.customer_plan_data,
          active: false
        }
        await Api.post(`/admin/customer/${customer.customer_id}`, {
          data: {
            customer_plan_data
          }
        }, true)
      }))
      await swal('Sucesso!', 'Todos os clientes foram congelados', 'success')
    } catch (err) {
      await swal('Erro!', 'Não foi possível congelar os clientes!', 'error')
      console.log(err)
    } finally {
      this.setState({ submitting: false })
    }
  }

  handleChangeCustomers(event) {
    const value = event.target.value
    const customers = value.split('\n')
    this.setState({ customers })
  }

  render() {
    return (
      <Form onSubmit={this.freezeCustomers}>
        <Form.Item
          label="Emails dos clientes"
          help='Os emails devem ser separados por quebras de linha.'
          style={{ maxWidth: '600px' }}
        >
          <Input.TextArea
            onChange={this.handleChangeCustomers}
            autosize={{ minRows: 20 }}

          />
        </Form.Item>

        <Form.Item>
          <Button loading={this.state.submitting} type="primary" htmlType="submit">
            Congelar
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

FreezeCustomers.propTypes = {
  location: PropTypes.any,
  router: PropTypes.any,
}
