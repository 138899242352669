import React from 'react'
import PropTypes from 'prop-types'

import {
  Tag
} from 'antd'

import moment from 'moment'

class SubscriptionRecords extends React.Component {
  getLastUpdateFormatted(updated_at) {
    const dateAndHourString = `${moment(updated_at).format('DD/MM/YYYY HH:mm')}h`

    const daysAgo = Math.round(
      Math.abs(
        moment.duration(
          moment(new Date(updated_at), 'YYYY-MM-DD')
            .diff(moment(new Date(), 'YYYY-MM-DD')))
          .asDays()
      )
    )

    const daysAgoString = `${daysAgo} dia${daysAgo > 1 ? 's' : ''} atrás`

    return `${dateAndHourString} (${daysAgoString})`
  }

  render() {
    return (
      <div style={{
        maxHeight: '300px',
        overflowY: 'scroll'
      }}>
        <table style={{ width: '100%' }}>
          <thead style={{ fontSize: '14px' }}>
            <th style={{ width: '50%' }}>Planos</th>
            <th style={{ width: '25%' }}>Última atualização</th>
            <th style={{ width: '25%' }}>Data de ativação</th>
          </thead>
          <tbody>
            <br />
            {this.props.customer_subscription
              .sort((subA) => {
                if (subA.deletedAt) {
                  return 1
                } else {
                  return -1
                }
              })
              .map((subscription, index) => (
                <tr key={subscription.created_at}>
                  <td style={{
                    borderTop: '1px solid rgb(226, 226, 226)',
                    height: '45px',
                    fontSize: '14px'
                  }}>
                    <span>
                      {(subscription.plan_ids || [subscription.plan_id]).map(plan_id => {
                        const plan = (this.props.plans.find(plan => plan.id === plan_id))

                        if (!plan) return ''

                        let galleryProProductPlanType = ''

                        if (plan.code.startsWith('sel_')) {
                          galleryProProductPlanType = 'Galerias e Seleção'
                        } else if (plan.code.startsWith('col_')) {
                          galleryProProductPlanType = 'Gallery Pro'
                        } else if (plan.code.startsWith('album_')) {
                          galleryProProductPlanType = 'Prova de Álbuns'
                        } else if (plan.code.startsWith('site_')) {
                          galleryProProductPlanType = 'Site'
                        }

                        return (
                          <Tag
                            color={galleryProProductPlanType ? '#56C568' : '#FF4949'}
                            style={{ opacity: subscription.deletedAt && '0.4' }}
                            key={subscription.created_at}
                          >
                            {`${galleryProProductPlanType ? `${galleryProProductPlanType}: ` : ''}${plan.name}`}
                          </Tag>
                        )
                      })}

                      {subscription.trial && (
                        <Tag color="#32c5d2" style={{ opacity: index !== 0 && '0.5' }}>Trial</Tag>
                      )}
                    </span>
                  </td>
                  <td style={{
                    borderTop: '1px solid rgb(226, 226, 226)',
                    height: '45px',
                    fontSize: '14px',
                    color: subscription.deletedAt && '#999'
                  }}>
                    <span>
                      {this.getLastUpdateFormatted(subscription.updated_at)}
                    </span>
                  </td>
                  <td style={{
                    borderTop: '1px solid rgb(226, 226, 226)',
                    height: '45px',
                    fontSize: '14px',
                    color: subscription.deletedAt && '#999'
                  }}>
                    {subscription.activation_date ? (
                      <span>
                        {moment(subscription.activation_date).format('DD/MM/YYYY HH:mm')}h
                      </span>
                    ) : (
                        <Tag color="red">
                          INATIVO
                        </Tag>
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

SubscriptionRecords.propTypes = {
  customer_subscription: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired
}


export default SubscriptionRecords
