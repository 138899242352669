import React, {Component} from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

function ReportListWidget (props) {
 return (
  <div className="portlet light">
    <div className="portlet-title">
      <div className="caption">
        <span className="caption-subject font-dark sbold uppercase">
          Relatórios
        </span>
      </div>
      <div className="tools" style={{padding: '0px'}}>
       <button
        type="button"
        className="btn green ladda-button" style={{marginRight: '5px'}} data-style="slide-right"
        onClick={() => props.generateReport()}
        >
        Gerar novo relatório de uso
        </button>
      </div>
    </div>
    <div className="portlet-body form">
      <table className="table table-hover">
       <thead>
        <tr>
         <th>#</th>
         <th>
          Tipo de relatório
         </th>
         <th>
          Data de criação
         </th>
         <th>
          Status
         </th>
        </tr>
       </thead>
       <tbody>
        {props.data.map((r) => {
         return (
           <tr key={'report-' + r.id}>
            <th scope="row">{r.id}</th>
            <td>
             {
              r.processing
              ? <span>
                <i className="fa fa-cog fa-spin" style={{fontSize: '18px'}}></i> {r.title}
               </span>
              : <a onClick={() => props.viewReport(r.id)}>{r.title}</a>
             }
            </td>
            <td>{format(r.created_at, 'DD/MM/YYYY HH:mm:ss')}</td>
            <td>{
              r.processing
              ? <strong className="font-yellow-crusta">Processando</strong>
              : <strong className="font-green-jungle">Disponível</strong>
             }</td>
           </tr>
         )
        })}
       </tbody>
      </table>
    </div>
  </div>
 )
}

export default ReportListWidget
