import React from 'react'
import PropTypes from 'prop-types'

import {
  Input,
  Select,
  Icon,
  Button,
  Form,
  Radio,
  Row,
  Col,
  Divider,
  Search,
  Switch
} from 'antd'

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
  className: 'ps-ant-formItem'
}

class CustomerDataForm extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { beta_access } = props.customer

    this.state = {
      submiting: false,
      user_email: props.user.email,
      ...props.user,
      ...props.customer,
      beta_blog_enabled: (
        !!beta_access &&
        beta_access.beta_blog_enabled &&
        beta_access.beta_blog_enabled != 0
      ),
      theme_id: props.customer.theme_id || undefined
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({ [key]: value })
  }


  submit(e) {
    e.preventDefault()
    this.setState({ submiting: true })

    this
      .props
      .saveData({
        user: { email: this.state.user_email },
        customer: {
          name: this.state.name,
          display_name: this.state.display_name,
          url: this.state.url,
          whatsapp_number: this.state.whatsapp_number,
          theme_id: this.state.theme_id,
          beta_access: {
            beta_blog_enabled: this.state.beta_blog_enabled
          }
        }
      })
      .then(() => {
        this.setState({ submiting: false })
      })
  }



  render() {
    return (
      <Form layout="horizontal" onSubmit={this.submit.bind(this)}>
        <Form.Item label="Nome" {...formItemLayout}>
          <Input name='name' value={this.state.name} onChange={this.onChange} />
        </Form.Item>
        <Form.Item label="Nome de exibição" {...formItemLayout}>
          <Input name='display_name' value={this.state.display_name} onChange={this.onChange} />
        </Form.Item>
        <Form.Item label="Email" {...formItemLayout}>
          <Input name='user_email' value={this.state.user_email} onChange={this.onChange} />
        </Form.Item>
        <Form.Item label="Whatsapp / Telefone" {...formItemLayout}>
          <Input name='whatsapp_number' value={this.state.whatsapp_number} onChange={this.onChange} />
        </Form.Item>
        <Form.Item label="URL" {...formItemLayout}>
          <Input
            suffix={(
              <Button
                type="primary"
                style={{ marginRight: '-11px', padding: '4px 10px 0' }}
                href={this.state.url}
                target='_blank'
              >
                Acessar site
            </Button>
            )}
            name='url' value={this.state.url} onChange={this.onChange}
          />
        </Form.Item>
        <Form.Item label="Tema" {...formItemLayout}>
          <Select
            showSearch
            name='theme_id'
            value={this.state.theme_id}
            onChange={(value) => this.onChange(value, 'theme_id')}
            placeholder="Selecione um tema"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.themes.map((theme, index) => (
              <Select.Option value={theme.id} key={`ps-theme-${index}`}>{theme.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Habilitar blog?" {...formItemLayout}>
          <Switch
            checked={this.state.beta_blog_enabled}
            onChange={(beta_blog_enabled) => this.onChange(beta_blog_enabled, 'beta_blog_enabled')}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 3, offset: 18 }}>
          <Button loading={this.state.submiting} type="primary" htmlType="submit" style={{ width: '100%' }}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default CustomerDataForm
