import React from 'react'
import PropTypes from 'prop-types'

import FormContext from '../context'

function InputTextarea (props) {
  return (
    <FormContext.Consumer>
    {(form) => {
      return (
        <div className={props.className}>
          <textarea
            name={props.name}
            value={form.inputs[props.name]}
            onChange={form.onChange}
            className="form-control"
            rows="4"
           />
        </div>
      )
    }}
  </FormContext.Consumer>
  )
}

InputTextarea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default InputTextarea
