import React from 'react'
import PropTypes from 'prop-types'

function FormGroup (props) {
  return (
    <div className="form-group">
      {props.children}
    </div>
  )
}

FormGroup.propTypes = {
  children: PropTypes.any
}

export default FormGroup
