import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Loading from 'app/widgets/Loading'
import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'
import format from 'date-fns/format'

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collections: false,
      inputs: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      stats: false
    }

    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.openPlatform = this.openPlatform.bind(this)
    this.props.router.push('/customer/list')
  }

  componentDidMount() {
    // this.getData()
  }

  async getData () {
    const stats = await Api.get('/customer/stats')
    if (stats) {
      return this.setState({ stats })
    }
  }

  handleChange (e) {
    const inputs = this.state.inputs
    inputs[e.target.name] = e.target.value
    this.setState({ inputs })
  }

  openPlatform (id) {
    if (id === 0) {
      Auth.crossLogin('site')
    } else if (id === 1) {
      Auth.crossLogin('select')
    }
  }

  onSubmit (e) {
    e.preventDefault()
    const inputs = this.state.inputs
    if (inputs['password'].length > 0 && inputs['password'] !== inputs['password_confirmation']) {
      alert ('A senha digitada e a confirmação não coincidem!')
      return
    }

    const { name, password } = inputs
  }

  render () {
    const stats = this.state.stats
    return (
      <div className="row">
        {
          !stats ?
          <Loading /> :
          <div>
            <div className="col-md-4">
            Teste
            </div>
            <div className="col-md-8">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">
                    <i className=" icon-bulb"/>
                    <span className="caption-subject font-dark sbold uppercase">
                      Soluções
                    </span>
                  </div>
                </div>
                <div className="portlet-body form row">

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

Dashboard.contextTypes = {
  router: PropTypes.object.isRequired
}

export default Dashboard
