import React from 'react'
import PropTypes from 'prop-types'

function FormButton (props) {
  return (
    <button {...props}
      type="button"
      className={`btn ${props.className}`}
      onClick={props.onClick}>
      {props.children}
    </button>
  )
}
//
// <button key="close" type="button" className="btn dark btn-outline" data-dismiss="modal">
//   Fechar
// </button>,
// <button onClick={() => this.props.submitSelection(this.state)} key="send" type="button" className="btn green ladda-button" data-style="slide-right" disabled={!validateEmail(this.state.client_email)}>
//   <span className="ladda-label">
//     <i className="fa fa-check" />
//     {
//       ['new', 'resend'].includes(this.props.action) ?
//       ' Salvar e enviar' :
//       ' Salvar'
//     }
//   </span>
// </button>

FormButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default FormButton
