import React from 'react'
import PropTypes from 'prop-types'

import FormContext from '../context'

function FormSelect (props) {
  return (
    <FormContext.Consumer>
    {(form) => {
      return (
        <div className={props.className}>
          <select
            {...props}
            name={props.name}
            value={form.inputs[props.name]}
            onChange={form.onChange}
            className="form-control"
            type={props.type}
          >
           {props.themes.map((p, index) => (
              <option key={p.id + '' + index} value={Number.parseInt(p.id)}>{p.name}</option>
            ))}
          </select>
        </div>
      )
    }}
  </FormContext.Consumer>
  )
}

FormSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default FormSelect
