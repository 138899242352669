import React, { Component } from 'react'
import ReactDom from 'react-dom'
import swal from 'sweetalert'
import Api from '../../modules/Api'

export default class GalleryDetailsDialog extends Component {

  constructor(props, context) {
    super(props, context)
    const { data } = props
    this.state = {
      data,
      urls: []
    }
    this.getUrls()
  }

  async getUrls() {
    if (!this.props.data || !this.props.data.id) {
      return
    }
    const result = await Api.get(`/admin/gallery-urls/${this.props.data.id}`)
    const { success, urls } = result || {}
    if (success && Array.isArray(urls)) {
      this.setState({ urls })
    }
    else {
      this.setState({ urls: [] })
    }
  }

  render() {
    const { data } = this.state
    return (
      <>
        <h4>{data.title}</h4>
        <p>
          ID: <strong>{data.id}</strong>
        </p>
        <div>
          {
            this.state.urls.length ?
              this.state.urls.map(url => <a href={url}>{url}</a>)
              : <span>Link não encontrado</span>
          }
        </div>
        {/* <pre style={{ background: '#fff', textAlign: 'left' }}>{JSON.stringify(data, null, 2)}</pre> */}
      </>
    )
  }
}

GalleryDetailsDialog.open = function (props) {
  const content = document.createElement('div')
  ReactDom.render(<GalleryDetailsDialog {...props} />, content)
  swal({
    content: content
  })
}
