import Main from 'app/layouts/Main'
import Auth from 'app/modules/Auth'

import LoginView from 'app/pages/Login'
import ErrorView from 'app/pages/Error'
import Dashboard from 'app/pages/Dashboard'
import CustomerListOld from 'app/pages/Customer/ListOld'
import CustomerList from 'app/pages/Customer/List'
import CustomerEditOld from 'app/pages/Customer/EditOld'
import CustomerEdit from 'app/pages/Customer/Edit'
import CustomerConfig from 'app/pages/Customer/Config'
import ReportList from 'app/pages/Report/List'
import ReportView from 'app/pages/Report/View'
import ConsolidUsageList from 'app/pages/ConsolidData/CustomerUsageList'
import ConsolidUsageView from 'app/pages/ConsolidData/CustomerUsageView'
import Plans from 'app/pages/Plans/Plans'
import Affiliate from 'app/pages/Affiliate'
import GalleriesPage from 'app/pages/Galleries'
import FreezeCustomers from 'app/pages/FreezeCustomers'

import Support from 'app/pages/Support'

const routes = {
  childRoutes: [
    {
      path: '/',
      component: Main,
      indexRoute: { onEnter: (nextState, replace) => replace('/dashboard') },
      onEnter: (nextState, replace) => {
        if (!Auth.isAuthenticated) {
          return replace('/login')
        }
      },
      childRoutes: [
        { path: 'dashboard', component: Dashboard },
        { path: 'customer/list/old', component: CustomerListOld },
        { path: 'customer/list', component: CustomerList },
        { path: 'report/list', component: ReportList },
        { path: 'report/:id/view', component: ReportView },
        { path: 'consolid/usage/list', component: ConsolidUsageList },
        { path: 'consolid/usage/:id/view', component: ConsolidUsageView },
        { path: 'affiliate/link', component: Affiliate },
        { path: 'affiliate/link/:id', component: Affiliate },
        { path: 'customer/:id/edit/old/:token', component: CustomerEditOld },
        { path: 'customer/:id/edit/old', component: CustomerEditOld },
        { path: 'customer/:id/edit/:token', component: CustomerEdit },
        { path: 'customer/:id/edit', component: CustomerEdit },
        { path: 'customer/:id/internal_variables', component: CustomerConfig },
        { path: 'subscription/plans', component: Plans },
        { path: 'support', component: Support },
        { path: 'galleries', component: GalleriesPage },
        { path: 'customers/freeze', component: FreezeCustomers },
        {
          path: '/logout',
          onEnter: (nextState, replace) => {
            Auth.logout()
          }
        }
      ]
    },
    {
      path: '/login',
      component: LoginView
    },
    {
      path: '/error',
      component: ErrorView
    }
  ]
}

export default routes
