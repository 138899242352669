/* eslint-disable react/display-name */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { Table, Tag, Button } from 'antd'
import moment from 'moment'

class ButtonToLogin extends Component {
  static propTypes = {
    loginAs: PropTypes.any
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      loading: false
    }
  }

  onClick() {
    this.setState({ loading: true })
    this
      .props
      .loginAs()
      .then(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Button
        shape="circle"
        icon="select"
        onClick={this.onClick.bind(this)}
        loading={this.state.loading}
      />
    )
  }
}

function ListItem(props) {
  const getDaysUntilTrialEnd = (expirationDate) => {
    const daysAgo = Math.round(
      moment.duration(
        moment(new Date(expirationDate), 'YYYY-MM-DD')
          .diff(moment(new Date(), 'YYYY-MM-DD')))
        .asDays()
    )

    return daysAgo
  }

  const nameColumn = {
    title: 'Nome',
    key: 'name',
    render: customer => (
      <>
        <span>#{customer.id}</span>
        <br />
        <a href={`/customer/${+customer.id}/edit/v2`}>{customer.name}</a>
        {customer.display_name && (
          <>
            <br />
            <small>Nome de exibição: {customer.display_name}</small>
          </>
        )}
      </>
    ),
  }

  const siteProPlans = {
    title: 'Site Pro',
    key: 'sitepro',
    children: [{
      title: 'Plano',
      key: 'plan',
      render: customer => {
        if (customer.site_pro_subscription) {
          if (customer.site_pro_subscription.deleted_at) {
            return (
              <Tag>
                Sem Assinatura Ativa
              </Tag>
            )
          } else {
            return (
              <Tag color="blue">
                {customer.site_pro_subscription.plan.name}
              </Tag>
            )
          }
        } else {
          return (
            <Tag >
              Nunca Assinou
            </Tag>
          )
        }
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: customer => {
        if (customer.site_pro_subscription && !customer.site_pro_subscription.deleted_at) {
          return (
            <Tag color={customer.site_pro_subscription.active ? 'green' : 'red'}>
              {customer.site_pro_subscription.active ? 'Ativo' : 'Congelado'}
            </Tag>
          )
        }
      }
    },
    {
      title: 'Trial',
      key: 'trial',
      render: customer => {
        if (customer.site_pro_subscription
          && !customer.site_pro_subscription.deleted_at
          && customer.site_pro_subscription.trial
        ) {
          const expirationDate = customer.site_pro_subscription.expiration_date
          if (!expirationDate) {
            return (
              <Tag color='red'>
                Sem data
              </Tag>
            )
          }
          const expiresIn = getDaysUntilTrialEnd(expirationDate)
          if (expiresIn > 1) {
            return (
              <Tag color='gold'>
                Expira em {expiresIn} dias
              </Tag>
            )
          } else {
            return (
              <Tag color='red'>
                Expirado
              </Tag>
            )
          }
        }
      }
    }
    ]
  }

  const galleryPropPlans = {
    title: 'Gallery Pro',
    key: 'gallerypro',
    children: [
      {
        title: 'Planos',
        key: 'plans',
        children: [
          {
            title: 'Site V1',
            key: 'site_v1_plan',
            render: customer => {
              if (!customer.gallery_pro_subscription) {
                return (
                  <Tag>
                    Nunca Assinou
                  </Tag>
                )
              }

              const sitePlan = customer.gallery_pro_subscription.plans.find(
                plan => plan.code.startsWith('site_')
              )

              if (sitePlan) {
                return (
                  <Tag color='blue'>
                    {sitePlan.name}
                  </Tag>
                )
              } else {
                return ''
              }
            }
          },
          {
            title: 'Gallery Pro',
            key: 'gallery_pro_plan',
            render: customer => {
              if (!customer.gallery_pro_subscription) {
                return (
                  <Tag>
                    Nunca Assinou
                  </Tag>
                )
              }

              const colPlan = customer.gallery_pro_subscription.plans.find(
                plan => plan.code.startsWith('col_')
              )

              if (colPlan) {
                return (
                  <Tag color='blue'>
                    {colPlan.name}
                  </Tag>
                )
              } else {
                return ''
              }
            }
          },
          {
            title: 'Galeria e Seleção',
            key: 'gallery_selection_plan',
            render: customer => {
              if (!customer.gallery_pro_subscription) {
                return (
                  <Tag>
                    Nunca Assinou
                  </Tag>
                )
              }

              const selPlan = customer.gallery_pro_subscription.plans.find(
                plan => plan.code.startsWith('sel_')
              )

              if (selPlan) {
                return (
                  <Tag color='blue'>
                    {selPlan.name}
                  </Tag>
                )
              } else {
                return ''
              }
            }
          },
          {
            title: 'Prova de Álbuns',
            key: 'album_plan',
            render: customer => {
              if (!customer.gallery_pro_subscription) {
                return (
                  <Tag>
                    Nunca Assinou
                  </Tag>
                )
              }

              const albumPlan = customer.gallery_pro_subscription.plans.find(
                plan => plan.code.startsWith('album_')
              )

              if (albumPlan) {
                return (
                  <Tag color='blue'>
                    {albumPlan.name}
                  </Tag>
                )
              } else {
                return ''
              }
            }
          }
        ]
      },
      {
        title: 'Status',
        key: 'status',
        render: customer => {
          if (customer.gallery_pro_subscription && !customer.gallery_pro_subscription.deleted_at) {
            return (
              <Tag color={customer.gallery_pro_subscription.active ? 'green' : 'red'}>
                {customer.gallery_pro_subscription.active ? 'Ativo' : 'Congelado'}
              </Tag>
            )
          }
        }
      },
      {
        title: 'Trial',
        key: 'trial',
        render: customer => {
          if (customer.gallery_pro_subscription
            && !customer.gallery_pro_subscription.deleted_at
            && customer.gallery_pro_subscription.trial
          ) {
            const expirationDate = customer.gallery_pro_subscription.expiration_date
            if (!expirationDate) {
              return (
                <Tag color='red'>
                  Sem data
                </Tag>
              )
            }

            const expiresIn = getDaysUntilTrialEnd(expirationDate)
            if (expiresIn > 1) {
              return (
                <Tag color='gold'>
                  Expira em {expiresIn} dias
                </Tag>
              )
            } else {
              return (
                <Tag color='red'>
                  Expirado
                </Tag>
              )
            }
          }
        }
      }
    ]
  }

  const emailColumn = {
    title: 'E-mail',
    dataIndex: 'user.email',
    key: 'email',
    render: (user_email, customer) => (
      <>
        <span><b>{user_email}</b></span>
        {customer.display_email && (<>
          <br />
          <small>Email de exibição: {customer.display_email}</small>
        </>)}
      </>
    )
  }

  const signupDateColumn = {
    title: 'Data de cadastro',
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => (
      <>
        <span>{format(created_at, 'DD/MM/YYYY')}</span>
        <br />
        <span>{format(created_at, 'HH:mm:ss')}</span>
      </>
    ),
  }

  const actionsColumn = {
    title: 'Ações',
    key: 'actions',
    render: function renderActions(c) {
      const url_tail = !!c.user && !!c.user.authentication_token && c.user.authentication_token
      return (
        <div style={{ displa: 'inline-block' }}>
          <Button
            shape="circle"
            type="primary"
            icon="edit"
            href={`/customer/${+c.id}/edit/${url_tail}`}
            target='_blank'
          />

          <Button
            shape="circle"
            icon="setting"
            style={{ marginLeft: '5px' }}
            href={'/customer/' + c.id + '/internal_variables'}
            target='_blank'
          />

          <Button
            shape="circle"
            icon="global"
            style={{ margin: '0 5px' }}
            href={c.url}
            target='_blank'
          />

          <ButtonToLogin loginAs={() => props.loginAs(c.id)} />
        </div>
      )
    }
  }

  const columns = [
    nameColumn,
    emailColumn,
    siteProPlans,
    galleryPropPlans,
    signupDateColumn,
    actionsColumn
  ]

  return (
    <Table
      columns={columns}
      dataSource={props.customers}
      rowKey={
        customer => customer.user.id
      }
      bordered
      scroll={{ x: 250 }}
      size="middle"
      pagination={{
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`
      }}
    />
  )
}

ListItem.propTypes = {
  loginAs: PropTypes.any,
  customers: PropTypes.arrayOf(PropTypes.object)
}

export default ListItem
