import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Auth from '../../modules/Auth'
import { Link } from 'react-router'

let navigationMenu = [
  {
    route: '/customer',
    defaultRoute: '/customer/list',
    title: 'Clientes',
    icon: 'fa fa-user',
    subroutes: [{
      route: '/customer/list',
      title: 'Lista de clientes'
    }
    ]
  },
  {
    route: '/subscription',
    defaultRoute: '/subscription/plans',
    title: 'Planos',
    icon: 'fa fa-diamond',
    subroutes: [
      {
        route: '/subscription/plans',
        title: 'Lista de planos'
      }
    ]
  },
  {
    route: '/affiliate',
    defaultRoute: '/affiliate/link',
    title: 'Afiliados',
    icon: 'fa fa-link',
    subroutes: [
      {
        route: '/affiliate/link',
        title: 'Link de Afiliados'
      }
    ]
  },
  {
    route: '/report',
    defaultRoute: '/report/list',
    title: 'Relatórios',
    icon: 'fa fa-map',
    subroutes: [
      {
        route: '/report/list',
        title: 'Lista de relatórios'
      },
      {
        route: '/consolid/usage/list',
        title: 'Customer Usage'
      }
    ]
  },
  {
    route: '/galleries',
    defaultRoute: '/galleries',
    title: 'Últimas Galerias',
    icon: 'fa fa-feed',
    subroutes: [
      {
        route: '/galleries',
        title: 'Últimas Galerias'
      }
    ]
  },
  {
    route: '/customers/freeze',
    defaultRoute: '/customers/freeze',
    title: 'Congelar Clientes',
    icon: 'fa fa-ban'
  }
]

class Sidebar extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      navigationMenu
    }

    this.activeRoute = this.isActive.bind(this)

    // TODO: melhorar método de filtrar menu

    const { subscription } = Auth.userData
    if (!subscription) return
    if (subscription.limits && !subscription.limits.rev) {
      this.state.navigationMenu = navigationMenu.filter((p) => !p.route.includes('/album'))
    }
  }
  isActive(routeName) {
    return this.props.location.pathname.includes(routeName)
  }
  render() {
    return (
      <div className="page-sidebar-wrapper">
        <div className="page-sidebar navbar-collapse collapse">
          <ul
            className="page-sidebar-menu menu-fixed page-header-fixed page-sidebar-menu-hover-submenu page-sidebar-menu-closed"
            data-keep-expanded="false"
            data-auto-scroll="true"
            data-slide-speed={200}>
            {this.state.navigationMenu.map((mainItem, mainIndex) => (
              <li className={'nav-item ' + (this.isActive(mainItem.route) && 'active open')} key={mainIndex}>
                <Link to={mainItem.defaultRoute || mainItem.route} className="nav-link nav-toggle">
                  <i className={mainItem.icon} style={{ color: !this.isActive(mainItem.route) && "white" }} />
                  <span className="title main">{mainItem.title}</span>
                  {this.isActive(mainItem.route) && <span className="selected" />}
                  <span className="arrow" />
                </Link>
                {
                  !mainItem.subroutes ? null :
                    (
                      <ul className="sub-menu">
                        {mainItem.subroutes.map((subItem, subIndex) => (
                          <li key={`${mainIndex}${subIndex}`} className={'nav-item ' + (this.isActive(subItem.route) && 'active open')}>
                            <Link to={subItem.route} className="nav-link nav-toggle">
                              <span className="title">{subItem.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object
}

export default Sidebar
