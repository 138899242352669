import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import { Dropdown, Menu } from 'antd'

import Auth from '~/modules/Auth'


function TopMenu () {
  const menu = (
    <Menu style={{ zIndex: 1000 }}>
      <Menu.Item key="0">
        <a onClick={() => Auth.crossLogin('account')}>
          <i className="icon-user" /> Conta
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/logout">
          <i className="icon-key" /> Sair
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="top-menu">
      <ul className="nav navbar-nav pull-right">
        <li>
          <div className="page-actions">
          <Link to="/support">
            <button type="button" className="btn red btn-circle btn-outline">
              <i className="fa fa-support" /> Suporte
            </button>
          </Link>
          </div>
        </li>
        <li className="dropdown dropdown-user">
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="dropdown-toggle">
              {/*<img
                className="img-circle"
                src="../assets/layouts/layout2/img/avatar3_small.jpg" />*/}
              <span className="username username-hide-on-mobile"> {Auth.userData.name} </span>
              <i className="fa fa-angle-down" />
            </a>
          </Dropdown>
        </li>
      </ul>
    </div>
  )
}
TopMenu.propTypes = {
  children: PropTypes.element
}

export default TopMenu
