import React from 'react'
import PropTypes from 'prop-types'

function FormLabel (props) {
  return (
    <label className={`control-label ${props.className}`}>
        {props.children}
    </label>
  )
}

FormLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
}

export default FormLabel
