import Input from './Input'
import InputTextarea from './Textarea'
import InputSelect from './Select'
import InputRadioButton from './RadioButton'
import InputAceEditor from './AceEditor'
import InputDate from './Date'

Input.Textarea = InputTextarea
Input.Date = InputDate
Input.AceEditor = InputAceEditor
Input.Select = InputSelect
Input.RadioButton = InputRadioButton

export default Input
