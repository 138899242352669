import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import Api from 'app/modules/Api'
import Loading from 'app/widgets/Loading'
import format from 'date-fns/format'

import ReportDataWidget from './ReportData'

class ReportView extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: false
    }

    this.getData = this.getData.bind(this)
    this.exportReport = this.exportReport.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate() {
  }

  async getData() {
    const { id } = this.props.params
    const data = await Api.get(`/admin/report/${id}`)
    console.log(data)
    if (data) {
      return this.setState({
        data
      })
    }
  }

  async exportReport() {
    // const {id} = this.props.params
    // const data = await Api.get(`/admin/report/${id}/export`)
    //
    // const content = document.createElement('a')
    // content.href = data.url
    // const contentButton = document.createElement('button')
    // contentButton.classList.add('swal-button', 'swal-button--confirm')
    // contentButton.style.backgroundColor = '#a3dd82'
    // contentButton.style.margin = '35px 0px'
    // const t = document.createTextNode('Baixar arquivo')
    // contentButton.appendChild(t)
    // content.appendChild(contentButton)
    // swal({
    //   icon: 'success',
    //   title: 'Arquivo pronto!',
    //   content: content,
    //   buttons: false
    // })
  }


  render() {
    const data = this.state.data
    return (
      <div className="row">
        {
          !data ?
            <Loading /> :
            <div className="col-md-12">
              <ReportDataWidget
                data={this.state.data}
                exportReport={this.state.data}
              />
            </div>
        }
      </div>
    )
  }
}

ReportView.contextTypes = {
  router: PropTypes.object.isRequired
}

export default ReportView
