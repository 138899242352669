import React from 'react'
import PropTypes from 'prop-types'

import FormContext from '../context'

function FormRadioButton (props) {
  return (
    <FormContext.Consumer>
    {(form) => {
      return (
        <label className={props.className}>
          <input 
            {...props}
            name={props.name}
            value={props.value}
            onChange={form.onChange}
            type="radio"
            defaultChecked={props.defaultChecked}
           />
           {props.label}<span/>
        </label>
      )
    }}
  </FormContext.Consumer>
  )
}

FormRadioButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultChecked: PropTypes.bool
}

export default FormRadioButton
