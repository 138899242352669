import React from 'react'
import PropTypes from 'prop-types'

import {
  Select,
  Button,
  Form,
  Row,
  Col,
  Switch,
  DatePicker
} from 'antd'

import Api from 'app/modules/Api'

import moment from 'moment'

const formItemStyles = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
  className: 'ps-ant-formItem'
}

class SiteProPlanForm extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.onChange = this.onChange.bind(this)

    this.siteProPlans = this.props.plans.filter(plan => plan.code === 'pro_site' || plan.code === 'free_site')

    this.state = {
      plan_id: null,
      submittingSave: false,
    }
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({ [key]: value })
  }

  async updatePlan(event) {
    event.preventDefault()

    this.setState({
      submittingSave: true
    })

    const planData = {
      plan_id: this.state.plan_id,
      active: this.state.active,
      trial: this.state.trial,
      expiration_date: this.state.expiration_date && this.state.expiration_date.toDate()
    }

    this.props.updatePlan(planData).then(() => {
      this.setState({
        submittingSave: false
      })
    })
  }

  async getData() {
    const siteProData = await Api.get(`/admin/customer/${this.props.customerId}/site-pro`)

    const { expiration_date } = siteProData

    this.setState({
      ...siteProData,
      expiration_date: expiration_date && moment(expiration_date)
    })
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <Form layout="horizontal" onSubmit={(event) => event.preventDefault()}>
        <Form.Item label="Ativo/Congelado" {...formItemStyles}>
          <Switch
            checked={this.state.active}
            onChange={(active) => this.onChange(active, 'active')}
            checkedChildren="Ativo"
            unCheckedChildren="Congelado"
          />
        </Form.Item>

        <Form.Item label="Plano" {...formItemStyles}>
          <Select
            name='site_plan_id'
            value={this.state.plan_id}
            onChange={(value) => this.onChange(value, 'plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>
              Nenhum
            </Select.Option>

            {this.siteProPlans.map((plan) => (
              <Select.Option value={+plan.id} key={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Trial" {...formItemStyles}>
          <Switch
            checked={this.state.trial}
            onChange={(trial) => this.onChange(trial, 'trial')}
          />
        </Form.Item>

        {!!this.state.trial && (
          <Form.Item label="Data de expiração" {...formItemStyles}>
            <DatePicker
              format="DD-MM-YYYY"
              value={this.state.expiration_date}
              onChange={(expiration_moment) => this.onChange(expiration_moment, 'expiration_date')}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ span: 21, offset: 0 }}>
          <Row>
            <Col
              span={24}
              style={{ textAlign: 'right', width: '100%', minWidth: 320 }}
            >
              <Button
                loading={this.state.submittingSave}
                type="primary"
                onClick={(event) => this.updatePlan(event)}
                style={{ width: '150px', marginLeft: 8 }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }
}

SiteProPlanForm.propTypes = {
  updatePlan: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired
}


export default SiteProPlanForm
