import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import Api from 'app/modules/Api'

import Loading from 'app/widgets/Loading'

import CustomerConfigForm from './Form'


class CustomerConfig extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: false,
      customer_data: {
        name: '',
        custom_css: '',
        custom_js: '',
        custom_variables: '',
        // internal_variables
        code_in: '',
        token_facebook: '',
        upload_quality: '',
        google_analytics: '',
        google_validation: '',
        token_enviar_formularios: ''
      }
    }

    this.getData = this.getData.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.updateInputState = this.updateInputState.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const { id } = this.props.params
    const data = await Api.get(`/admin/customer/${id}`)
    if (data) {
      const { customer_data } = data
      window.document.title = `${customer_data.name} | PICSIZE`
      return this.setState({
        data: true,
        customer_data: {
          ...this.state.customer_data,
          ...customer_data,
          ...(customer_data.internal_variables || {})
        }
      })
    }
  }

  async submitForm (formData) {
    const { id } = this.props.params

    try {
      const response = await Api.post(`/admin/customer/${id}`, {
        data: {
          customer_data: {
            ...formData,
            name: '',
            internal_variables: {
              code_in: formData.code_in,
              token_facebook: formData.token_facebook,
              upload_quality: formData.upload_quality,
              google_analytics: formData.google_analytics,
              google_validation: formData.google_validation,
              token_enviar_formularios: formData.token_enviar_formularios,
            },
            custom_variables: formData.custom_variables && JSON.parse(formData.custom_variables)
          }
        }
      })
      if (response.success) {
        return swal('Pronto!', 'Informações atualizadas!', 'success')
      }
    } catch (e) {
      console.error(e)
    }
    await swal('Erro!', 'Não foi possível atualizar!', 'error')
  }

  updateInputState (e, formId) {
    this.setState({ [formId]: {
      ...this.state[formId],
      [e.target.name]: e.target.value
    } }, () => console.log(this.state))
  }

  render () {
    const { id } = this.props.params
    const data = this.state.data
    if (!data) {
      return (
        <div className="row">
          <Loading />
        </div>
      )
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="portlet light ">
          <div className="portlet-title tabbable-line">
            <div className="caption caption-md">
              <i className="icon-globe theme-font hide" />
              <span className="caption-subject font-green bold uppercase">
                Personalizações do cliente:
                <span className="font-red" style={{ marginLeft: '10px' }}>
                  {!!this.state.customer_data.name && this.state.customer_data.name}
                </span>

              </span>
            </div>
          </div>
          <div className="portlet-body">
            <CustomerConfigForm inputs={this.state.customer_data} onSubmit={this.submitForm} />
          </div>
        </div>
        </div>
      </div>
    )
  }
}

CustomerConfig.contextTypes = {
  router: PropTypes.object.isRequired
}

export default CustomerConfig
