import React, { Component } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

import Api from '~/modules/Api'


import { Modal } from 'antd';

function formatBytes(bytes, decimals) {
  if (bytes == 0) return '0 Bytes'
  let k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function StatusItem(props) {
  return (
    <li className="mt-list-item">
      <div className="pull-right">{props.value}</div>
      <div className="list-item-content" style={{ padding: '0px' }}>
        <strong>
          {props.name}
        </strong>
      </div>
    </li>
  )
}

class CustomerEditSidebar extends Component {

  state = {
    modalVisible: false,
    link: '',
    emailClient: this.props.user.email,
    firstDate: this.props.user_created_at,
    secondDate: this.props.infos.usage.pc_return,
  }

  async updatePass(email) {
    event.preventDefault()

    const result = await Api.passRecovery(`/account/requestRecover?email=${email.email}`)

    this.setState({ link: result.url }, () => this.setState({ modalVisible: true }))
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      modalVisible: false,
    });
  }

  subtractDates = () => {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    const diff = Math.round(Math.abs((new Date(/*this.state.secondDate*/this.props.infos.usage.first_selection_at || this.props.infos.usage.first_generic_link_at).getTime() - new Date(this.state.firstDate).getTime()) / (oneDay)));

    return diff
  }


  render() {
    /*console.log(this.props.infos.about)
    console.log(this.props.infos.usage.pc_return)
    console.log(this.props.user_created_at)
    console.log(this.props.infos.usage)
    console.log(this.props.device)*/

    let fistSelection = this.props.infos.usage.first_selection_at ? format(this.props.infos.usage.first_selection_at, 'DD/MM/YYYY HH:mm:ss') : '-'
    let firstLink = this.props.infos.usage.first_generic_link_at ? format(this.props.infos.usage.first_generic_link_at, 'DD/MM/YYYY HH:mm:ss') : '-'
    let returnDays = this.props.infos.usage.days_to_use_pc
    let usageDays = this.subtractDates()
    return (
      <div className="portlet light portlet-fit">
        <div className="portlet-title">
          <div className="caption">
            <i className=" icon-layers font-green" />
            <span className="caption-subject font-green bold uppercase">Cadastro</span>
          </div>
          <button type="button" className="btn red ladda-button pull-right" data-style="slide-right" onClick={this.props.loginAs}>
            <span className="ladda-label">
              <i className="fa fa-user" />
              </span>
          </button>
          <a href={`/affiliate/link/${this.props.id}`} target="_blank">
            <button type="button" className="btn blue ladda-button pull-right" data-style="slide-right" style={{ marginRight: '5px' }}>
              <span className="ladda-label">
                <i className="fa fa-link" />
                </span>
            </button>
          </a>
          
          <a href={'/customer/' + this.props.id + '/internal_variables'} target='_blank'>
            <button className="btn yellow ladda-button pull-right" style={{ marginRight: '5px' }} >
              <span className="ladda-label">
                <i className="fa fa-cog" />
              </span>
            </button>
            <button onClick={() => this.updatePass(this.props.user)} className="btn ladda-button pull-right fa fa-lock" style={{ marginRight: '5px' }} >
            </button>
          </a>
        </div>
        <div className="portlet-body">
          <div className="mt-element-list">
            <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
              <ul>
                <li className="mt-list-item">
                  <div className="pull-right">
                    <a className="ladda-label" onClick={this.props.showTrack}>
                      <i className="fa fa-road" />
                    </a>
                  </div>
                  <div className="list-item-content" style={{ padding: '0px' }}>
                    <strong>Indicação</strong>
                  </div>
                </li>
                <StatusItem name="Data de cadastro" value={this.props.user_created_at ? format(this.props.user_created_at, 'DD/MM/YYYY HH:mm:ss') : '-'} />
                <StatusItem name="Data de expiração" value={this.props.usage.expiration_date ? format(this.props.usage.expiration_date, 'DD/MM/YYYY HH:mm:ss') : '-'} />
                <StatusItem name="Último login" value={this.props.usage.account_last_login ? format(this.props.usage.account_last_login, 'DD/MM/YYYY HH:mm:ss') : '-'} />
                <StatusItem name="Dispositivo" value={this.props.device.ua == "" ? "Desktop" : "Mobile"} />
                {
                  this.props.device.ua == "" ? null : <StatusItem name="Voltou pelo PC" value={returnDays == undefined ? "não voltou ainda" : returnDays == 0 ? `no mesmo dia` : returnDays == 1 ? `após 1 dia` : `após ${returnDays} dias`} />
                }
              </ul>
              <Modal
                visible={this.state.modalVisible}
                onOk={this.handleOk}
                closable={false}
                cancelButtonProps={{ style: { display: "none" } }}
              >
                <p>E-mail de recuperação de senha enviado para: <b>{this.state.emailClient}</b></p>
                <p>Link de recuperação: <a href={this.state.link} target="new">{this.state.link}</a></p>
              </Modal>
            </div>
          </div>
        </div>

        <div className="portlet-title">
          <div className="caption">
            <i className=" icon-layers font-green" />
            <span className="caption-subject font-green bold uppercase">Galerias</span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="mt-element-list">
            <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
              <ul>
                <StatusItem name="Galerias Ativas" value={`${this.props.gallery_info.count} (${this.props.gallery_info.total_size ? formatBytes((+this.props.gallery_info.total_size), 2) : '0 MB'})`} />
                <StatusItem name="Galerias Deletadas" value={`${this.props.deleted_gallery_info.count} (${this.props.deleted_gallery_info.total_size ? formatBytes((+this.props.deleted_gallery_info.total_size), 2) : '0 MB'})`} />
                <StatusItem name="Fotos nas galerias" value={this.props.gallery_info.total_pictures || 0} />
                <StatusItem name="Última galeria" value={this.props.gallery_info.last ? format(this.props.gallery_info.last, 'DD/MM/YYYY HH:mm:ss') : '-'} />
              </ul>
            </div>
          </div>
        </div>

        <div className="portlet-title">
          <div className="caption">
            <i className=" icon-layers font-green" />
            <span className="caption-subject font-green bold uppercase">Seleção de fotos</span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="mt-element-list">
            <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
              <ul>
                <StatusItem name="Coleções Ativas" value={`${this.props.collection_info.count} (${this.props.collection_info.total_size ? formatBytes((+this.props.collection_info.total_size), 2) : '0 MB'})`} />
                <StatusItem name="Coleções Deletadas" value={`${this.props.deleted_collection_info.count} (${this.props.deleted_collection_info.total_size ? formatBytes((+this.props.deleted_collection_info.total_size), 2) : '0 MB'})`} />
                <StatusItem name="Fotos nas coleções" value={this.props.collection_info.total_pictures || 0} />
                <StatusItem name="Última coleção" value={this.props.collection_info.last ? format(this.props.collection_info.last, 'DD/MM/YYYY HH:mm:ss') : '-'} />
                <StatusItem name="1º seleção criada" value={fistSelection == "Invalid Date" ? "-" : fistSelection} />
                <StatusItem name="1º link criado" value={firstLink == "Invalid Date" ? "-" : firstLink} />
                <StatusItem name="Utilizou?" value={isNaN(usageDays) ? "Não" : usageDays == 0 ? `no mesmo dia` : usageDays == 1 ? `após 1 dia` : `após ${usageDays} dias`} />
              </ul>
            </div>
          </div>
        </div>

        <div className="portlet-title">
          <div className="caption">
            <i className=" icon-layers font-green" />
            <span className="caption-subject font-green bold uppercase">Site</span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="mt-element-list">
            <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
              <ul>
                <StatusItem name="Álbums Ativos" value={this.props.active_site_info.active_albuns || '-'} />
                <StatusItem name="Álbums Deletados" value={this.props.deleted_site_info.deleted_albuns || '-'} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerEditSidebar
