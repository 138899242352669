import React from 'react'
import PropTypes from 'prop-types'

import SelectDate from 'app/widgets/SelectDate'

import FormContext from '../context'

const selectHandler = (form, name, date) => {
  if (!date) return
  form.onChange({
    target: {
      name,
      value: (typeof date.toISOString === 'function' ? date.toISOString() : date)
    }
  })
}

function InputDate (props) {
  return (
    <FormContext.Consumer>
    {(form) => {
      return (
        <div className={props.className}>
          <SelectDate
            selectHandler={(date) => selectHandler(form, props.name, date)}
            selectedDate={form.inputs[props.name]}
            style={{}}
            disabledDays={[{}]}
            />
        </div>
      )
    }}
  </FormContext.Consumer>
  )
}

InputDate.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default InputDate
