import React from 'react'
import PropTypes from 'prop-types'

import {
  Input,
  Select,
  Icon,
  Button,
  Form,
  Radio,
  Row,
  Col,
  Divider,
  Search,
  Switch,
  DatePicker
} from 'antd'

import moment from 'moment'

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
  className: 'ps-ant-formItem'
}

class CustomerPlanForm extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.planMap = {
      site: this.props.plans.filter((p) => p.code.startsWith('site_')),
      col: this.props.plans.filter((p) => p.code.startsWith('col_')),
      sel: this.props.plans.filter((p) => p.code.startsWith('sel_')),
      album: this.props.plans.filter((p) => p.code.startsWith('album_')),
    }
    this.planIdMap = {
      site: this.planMap.site.map((p) => p.id),
      col: this.planMap.col.map((p) => p.id),
      sel: this.planMap.sel.map((p) => p.id),
      album: this.planMap.album.map((p) => p.id)
    }

    this.state = {
      submiting: false,
      submiting2: false,
      ...this.props.customerPlan,
    }

    if (this.state.plan_ids) {
      this.state.site_plan_id = this.state.plan_ids.find((value) => this.planIdMap.site.indexOf(value) > -1)
      this.state.col_plan_id = this.state.plan_ids.find((value) => this.planIdMap.col.indexOf(value) > -1)
      this.state.sel_plan_id = this.state.plan_ids.find((value) => this.planIdMap.sel.indexOf(value) > -1)
      this.state.album_plan_id = this.state.plan_ids.find((value) => this.planIdMap.album.indexOf(value) > -1)
    }

    if (this.state.expiration_date) {
      this.state.expiration_date = moment(this.state.expiration_date)
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({ [key]: value })
  }

  async submit(e, type = false, s) {
    e.preventDefault()
    s == "s2" ? this.setState({ submiting2: true }) : this.setState({ submiting: true })

    let plan_ids = [this.state.site_plan_id, this.state.sel_plan_id, this.state.album_plan_id].filter((p) => !!p)
    
    if (!!this.state.col_plan_id) {
      plan_ids = [this.state.col_plan_id, this.state.site_plan_id].filter((p) => !!p)
      this.state.sel_plan_id = this.state.album_plan_id = undefined
    }

    this
      .props
      .saveData({
        plan_ids,
        active: this.state.active,
        trial: this.state.trial,
        expiration_date: this.state.expiration_date ? this.state.expiration_date.toISOString() : undefined
      }, type)
      .then(() => {
        s == "s2" ? this.setState({ submiting2: false }) : this.setState({ submiting: false })
      })
  }



  render() {
    return (
      <Form layout="horizontal" onSubmit={this.submit.bind(this)}>
        <Form.Item label="Site" {...formItemLayout}>
          <Select
            name='site_plan_id'
            value={this.state.site_plan_id}
            onChange={(value) => this.onChange(value, 'site_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>Nenhum</Select.Option>
            {this.planMap.site.map((plan, index) => (
              <Select.Option value={+plan.id} key={`ps-plan-${index}`}>{plan.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Gallery Pro" {...formItemLayout}>
          <Select
            name='col_plan_id'
            value={this.state.col_plan_id}
            onChange={(value) => this.onChange(value, 'col_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option key='ps-plan-default' value={undefined}>Nenhum</Select.Option>
            {this.planMap.col.map((plan, index) =>
              <Select.Option value={+plan.id} key={`ps-plan-${index}`}>{plan.name}</Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Galerias e Seleção" {...formItemLayout}>
          <Select
            name='sel_plan_id'
            value={this.state.sel_plan_id}
            onChange={(value) => this.onChange(value, 'sel_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>Nenhum</Select.Option>
            {this.planMap.sel.map((plan, index) => (
              <Select.Option value={+plan.id} key={`ps-plan-${index}`}>{plan.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Prova de Álbuns" {...formItemLayout}>
          <Select
            name='album_plan_id'
            value={this.state.album_plan_id}
            onChange={(value) => this.onChange(value, 'album_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>Nenhum</Select.Option>
            {this.planMap.album.map((plan, index) => (
              <Select.Option value={+plan.id} key={`ps-plan-${index}`}>{plan.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Inscrição Ativa" {...formItemLayout}>
          <Switch
            checked={this.state.active}
            onChange={(active) => this.onChange(active, 'active')}
          />
        </Form.Item>
        <Form.Item label="Trial" {...formItemLayout}>
          <Switch
            checked={this.state.trial}
            onChange={(trial) => this.onChange(trial, 'trial')}
          />
        </Form.Item>
        {!!this.state.trial && (
          <Form.Item label="Data de expiração" {...formItemLayout}>
            <DatePicker
              format="DD-MM-YYYY"
              value={this.state.expiration_date}
              onChange={(expiration_moment) => this.onChange(expiration_moment, 'expiration_date')}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ span: 21, offset: 0 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'right', width: "100%", minWidth: 320 }}>
              <Button loading={this.state.submiting} htmlType="submit" style={{ width: '150px' }}>
                Salvar e Publicar
              </Button>
              <Button loading={this.state.submiting2} type="primary" htmlType="submit" onClick={(e, type = true, s) => this.submit(e, type, "s2")} style={{ width: '150px', marginLeft: 8 }}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }
}

export default CustomerPlanForm
