import Api from './Api'
import swal from 'sweetalert'

const based64Encode = window.btoa
const based64Decode = window.atob

const Auth = {
  async tryLogin (email, password) {
    const result = await Api.post('/admin/login', {email, password})
    if (result.success) {
      localStorage.setItem('auth-time', +new Date)
      this.userData = result.user
    }
    return result
  },
  async crossLogin (service) {
    const result = await Api.post('/login/cross', {service})
    window.location = result.url
  },
  async loginAs (customer_id) {
    const content = document.createElement('div')
    content.style = 'text-align: center; font-size: 14px;padding: 50px 10px 10px 10px;word-wrap: break-word; word-break: break-all;'
    const {url} = await Api.get(`/admin/customer/${customer_id}/personificate`)

    if (url) {
      content.innerHTML = `<strong>URL de login:</strong> <a href='${url}' target="_blank">${url}</a>`
    } else {
      content.innerHTML = `Não foi possível encontrar um usuário vinculado ao customer_id ${customer_id}`
    }

    await swal({
      content,
      dangerMode: !url,
      title: url ? false : 'Erro!',
      icon: url ? false : 'error',
      buttons: {
        cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true,
      }
      }
    })
  },
  async logout () {
    localStorage.removeItem('auth-time')
    localStorage.removeItem('auth-userData')
    await Api.get('/admin/logout')
    return window.location = '/'
  },
  get isAuthenticated () {
    return localStorage.getItem('auth-time') !== null && !!this.userData
  },
  get userData () {
    const encodedData = localStorage.getItem('auth-userData')
    if (!encodedData || based64Decode(encodedData) === 'undefined') return false
    return JSON.parse(based64Decode(encodedData))
  },
  set userData (val) {
    localStorage.setItem('auth-userData', based64Encode(JSON.stringify(val)))
  }
}

export default Auth
