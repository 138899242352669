import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Api from 'app/modules/Api'
import PlansListWidget from './Widget'

import AceEditor from 'react-ace'
import 'brace/mode/json'
import 'brace/mode/css'
import 'brace/mode/javascript'
import 'brace/theme/github'
import 'brace/theme/tomorrow'

import { Modal, Button } from 'antd';

export default class Plans extends Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            data: null,
            isEditModalVisible: false,
            isViewModalVisible: false,
            jsonData: null
        }
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const data = await Api.get('/admin/plans')
        if (Array.isArray(data)) {
            for (const i of data) {
                if (i.code && i.code.startsWith('album')) {
                    i.category = 'Album'
                }
                else if (i.code && i.code.startsWith('sel')) {
                    i.category = 'Seleção'
                }
                else if (i.code && i.code.startsWith('col')) {
                    i.category = 'Coleção/Galeria'
                }
                else if (i.code && i.code.startsWith('site')) {
                    i.category = 'Site'
                }
            }
            data.sort(function (a, b) {
                a = a.category || '|||'
                b = b.category || '|||'
                if (a < b) { return -1; }
                if (a > b) { return 1; }
                return 0;
            })
        }
        this.setState({ data })
    }

    handleModalCancelButton = () => {
        this.setState({
            isEditModalVisible: false,
        });
    };

    handleModalSaveButton = () => {
        let jsonData = this.state.jsonData;
        const planLimitsToJSON = JSON.parse(this.state.modalEditableField);
        jsonData.limits = planLimitsToJSON;

        Api.updatePlan('/admin/plans', jsonData);
        this.setState({
            isEditModalVisible: false,
            jsonData,
        });
    };

    handleUpdatePlanInput = (event) => {
        this.setState({
            modalEditableField: event
        })
    };

    view(plan) {
        this.setState({
            modalEditableField: JSON.stringify(plan, null, 4),
            isViewModalVisible: true,
        })
    }

    edit(plan) {
        if (this.state.jsonData !== plan) {
            this.setState({ jsonData: plan }, () => {
                const planLimits = JSON.stringify(this.state.jsonData.limits, null, 2);
                this.setState({
                    modalEditableField: planLimits,
                    isEditModalVisible: true,
                })
            });
        }
        else {
            const planLimits = JSON.stringify(this.state.jsonData.limits, null, 2);
            this.setState({
                modalEditableField: planLimits,
                isEditModalVisible: true,
            })
        }
    }

    render() {
        return (
            <div className='admin-subscription-plans-page row'>
                <div className='col-md-12'>
                    <div className="portlet light">
                        <div className="portlet-title">
                            <div className="caption">
                                <span className="caption-subject font-dark sbold uppercase">
                                    Lista de Planos
                                </span>
                            </div>
                            <div className="tools" style={{ padding: '0px' }}>
                                {/* <div className="input-icon right">
                                <i className="icon-magnifier"></i>
                                <input className="form-control" onChange={this.searchOnList} placeholder="Pesquisar por cliente" type="text" />
                                </div> */}
                            </div>
                        </div>
                        <div className="portlet-body form">
                            {Array.isArray(this.state.data) &&
                                <PlansListWidget
                                    data={this.state.data}
                                    onSelect={plan => this.edit(plan)}
                                    onView={plan => this.view(plan)}
                                />
                            }
                        </div>
                        <Modal
                            title="Editar Plano"
                            visible={this.state.isEditModalVisible}
                            onCancel={this.handleModalCancelButton}
                            maskClosable={false}
                            footer={[
                                <Button key="submit" type="primary" onClick={this.handleModalSaveButton}>Salvar</Button>
                            ]}>
                                <AceEditor
                                    mode="json"
                                    theme="github"
                                    width="100%"
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    value={this.state.modalEditableField}
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 2,
                                        minLines: 15,
                                        fontSize: 14,
                                    }}
                                    onChange={(value) => this.handleUpdatePlanInput(value)}
                                    editorProps={{$blockScrolling: true}}
                                />
                        </Modal>
                        <Modal
                            title="Ver Plano"
                            visible={this.state.isViewModalVisible}
                            onCancel={() => this.setState({isViewModalVisible: false})}
                            maskClosable={false}
                            footer={[
                                <Button key="submit" type="primary" onClick={() => this.setState({isViewModalVisible: false})}>Fechar</Button>
                            ]}>
                                <pre>{this.state.modalEditableField}</pre>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}