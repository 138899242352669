import React, { Component } from 'react'
import PropTypes from 'prop-types'

import swal from 'sweetalert'

import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'
import Loading from 'app/widgets/Loading'

import PanelBox from 'app/widgets/PanelBox'
import CustomerEditSidebar from './Sidebar'
import CustomerForm from './CustomerForm'
import GalleryProPlanForm from './GalleryProPlanForm'
import SiteProPlanForm from './SiteProPlanForm'

import './style.scss'
import SubscriptionRecords from './SubscriptionRecords'

class CustomerEdit extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: false
    }

    this.id = this.props.params.id
    this.getData = this.getData.bind(this)
    this.updateCustomer = this.updateCustomer.bind(this)
    this.updateCustomerPlan = this.updateCustomerPlan.bind(this)
    this.updateSiteProPlan = this.updateSiteProPlan.bind(this)
    this.refreshSubscriptions = this.refreshSubscriptions.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    const [data, plans, themes] = await Promise.all([
      Api.get(`/admin/customer/${this.id}`),
      Api.get('/plan/info'),
      Api.get('/theme/info')
    ])

    const customerUsageInfo = await Api.get(`/admin/customer/collection/${this.id}`)

    if (!data || !customerUsageInfo) return
    const { user_data, customer_data, customer_plan_data, customer_usage, user_created_at, infos, device, customer_subscription } = data

    return this.setState({
      data: true,
      user_data,
      customer_data,
      customer_plan_data,
      customer_usage,
      plans,
      themes,
      user_created_at,
      infos,
      device,
      collection_info: customerUsageInfo.collection_info,
      gallery_info: customerUsageInfo.gallery_info,
      deleted_collection_info: customerUsageInfo.deleted_collection_info,
      deleted_gallery_info: customerUsageInfo.deleted_gallery_info,
      active_site_info: customerUsageInfo.active_site_info,
      deleted_site_info: customerUsageInfo.deleted_site_info,
      customer_subscription
    })
  }

  async refreshSubscriptions() {
    const data = await Api.get(`/admin/customer/${this.id}`)

    if (!data) return

    const { customer_subscription } = data

    return this.setState({ customer_subscription })
  }

  async updateCustomer(data) {
    const new_email = this.state.user_data.email !== data.user.email ? data.user.email : undefined
    const response = await Api.post(`/admin/customer/${this.id}`, {
      data: { customer_data: data.customer, new_email }
    }, true)
    if (response.success) {
      if (new_email) {
        this.state.user_data.email = new_email
      }
      Api.get(`/update/data?customer_id=${this.id}`)
      return swal('Pronto!', 'Informações atualizadas!', 'success')
    }
    if (response && response.error === 'E-mail já registrado!') {
      return await swal('Erro!', 'Esse email já esta sendo usado por outra conta!', 'error')
    }
    await swal('Erro!', 'Não foi possível atualizar!', 'error')
  }

  async updateCustomerPlan(customer_plan_data, notOpenWindow) {
    let planCodes = []
    for (let plan of this.state.plans) {
      for (let plan_id of customer_plan_data.plan_ids) {
        if (plan.id === plan_id) {
          planCodes.push(plan.code)
        }
      }
    }
    const url = this.state.customer_data.url
    const response = await Api.post(`/admin/customer/${this.id}`, {
      data: {
        customer_plan_data,
        url,
        plans: planCodes,
        email: this.state.user_data.email
      }
    }, true)
    if (response.success) {
      notOpenWindow ? null : window.open(url)
      Api.get(`/update/data?customer_id=${this.id}`)
      await this.refreshSubscriptions()
      return swal('Pronto!', 'Informações atualizadas!', 'success')
    }
    await swal('Erro!', 'Não foi possível atualizar!', 'error')
  }

  async updateSiteProPlan(planData) {
    const response = await Api.post(`/admin/customer/${this.id}/site-pro`, {
      planData
    }, true)

    if (response.success) {
      Api.get(`/update/data?customer_id=${this.id}`)
      await this.refreshSubscriptions()
      return swal('Pronto!', 'Informações atualizadas!', 'success')
    }
    await swal('Erro!', 'Não foi possível atualizar!', 'error')
  }

  async showTrack() {
    const content = document.createElement('div')
    content.style = 'text-align: center; font-size: 14px;padding: 0 10px 10px 10px;'
    const track = this.state.customer_data.track
    if (track) {
      let html = `<table style="width:100%;">
        <thead>
          <th style='text-align: center;'>Data</th>
          <th style='text-align: center;'>Key</th>
        </thead>
        <tbody>
      `
      for (const t of track) {
        html += `
        <tr>
          <td>${new Date(t.timestamp).toLocaleString('pt-BR', { hour12: false, timeZone: 'America/Sao_Paulo' })}</td>
          <td>${t.key}</td>
        </tr>
        `
      }
      html += '</tbody></table>'
      content.innerHTML = html
    }

    await swal({
      content,
      title: 'Indicações',
      buttons: {
        cancel: {
          text: 'Fechar',
          value: false,
          visible: true,
          closeModal: true,
        }
      }
    })
  }

  render() {
    if (!this.state.data) {
      return (
        <div className="row">
          <Loading />
        </div>
      )
    }
    return (
      <div className="row">
        <div className="col-md-4">
          <CustomerEditSidebar
            loginAs={() => Auth.loginAs(this.id)}
            showTrack={() => this.showTrack()}
            usage={this.state.customer_usage}
            id={this.id}
            user={this.state.user_data}
            user_created_at={this.state.user_created_at}
            infos={this.state.infos}
            device={this.state.device}
            collection_info={this.state.collection_info}
            deleted_collection_info={this.state.deleted_collection_info}
            gallery_info={this.state.gallery_info}
            deleted_gallery_info={this.state.deleted_gallery_info}
            active_site_info={this.state.active_site_info}
            deleted_site_info={this.state.deleted_site_info}
          />
        </div>
        <div className="col-md-8">
          <div className="profile-content">
            <PanelBox title="Dados do Cliente">
              <PanelBox.Item title="Dados Gerais">
                <CustomerForm
                  saveData={this.updateCustomer}
                  user={this.state.user_data}
                  customer={this.state.customer_data}
                  themes={this.state.themes}
                />
              </PanelBox.Item>
              <PanelBox.Item title="Dados do Produto Gallery Pro">
                <GalleryProPlanForm
                  saveData={this.updateCustomerPlan}
                  customerPlanData={this.state.customer_plan_data}
                  plans={this.state.plans}
                />
              </PanelBox.Item>
              <PanelBox.Item title="Dados do Produto Site Pro">
                <SiteProPlanForm
                  updatePlan={this.updateSiteProPlan}
                  plans={this.state.plans}
                  customerId={this.id}
                />
              </PanelBox.Item>
              <PanelBox.Item title="Histórico de Assinaturas">
                <SubscriptionRecords
                  customer_subscription={this.state.customer_subscription}
                  plans={this.state.plans}
                />
              </PanelBox.Item>
            </PanelBox>
          </div>
        </div>
      </div>
    )
  }
}

CustomerEdit.contextTypes = {
  router: PropTypes.object.isRequired
}

export default CustomerEdit
