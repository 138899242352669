import React, {Component} from 'react'
import PropTypes from 'prop-types'

var styles = {
  content: {
    margin: 'auto',
    marginTop: '15%',
    marginBottom: '15%',
    fontSize: '25px',
    color: '#26344B',
    width: '350px',
    textAlign: 'center'
  }
}

function Loading (props) {
  return (
    <div style={styles.content}>
      <div style={{display: 'block',  marginBottom: '25px'}}>
        <i className="fa fa-camera-retro fa-spin fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span>
      </div>
    </div>)
}

export default Loading
