import ReactDOM from 'react-dom'
import { Router, browserHistory } from 'react-router'
import routes from './routes'
//
import 'jquery'
import 'toastr'

import 'styles/fonts.css'
import 'simple-line-icons/simple-line-icons.min.css'
import 'styles/metronic/lib.scss'
import 'toastr/build/toastr.css'
import 'styles/metronic/components-rounded.min.css'

// import 'animate.css/animate.min.css'
// import './../public/styles/style.css'
import 'custom/widgets.scss'
// import './../public/styles/custom/loading.scss'

import './style.scss'

ReactDOM.render(
  <Router history={browserHistory}>{routes}</Router>,
  document.getElementById('root')
)
