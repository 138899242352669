import Form from './Form'
import FormButton from './FormButton'
import FormGroup from './FormGroup'
import FormInput from './Input'
import FormLabel from './Label'

import FormContext from './context'

Form.Group = FormGroup
Form.Button = FormButton
Form.Input = FormInput
Form.Label = FormLabel
Form.Consumer = FormContext.Consumer

export default Form
