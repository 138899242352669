import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import {
  Form, Input, Select, Button, AutoComplete,
} from 'antd';

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'


class CadForm extends Component {

  state = {
    referencia: '',
    key: '',
    redirect: '',
    afiliado: '',
    buttonState: true,
    stateError: '',
    customer_id: ''
  };

  inputValues(e, state) {
    this.setState({
      [state]: e.target.value
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Api.newLink('/admin/affiliateLink', {
      created_at: new Date(),
      affiliate: this.props.customer,
      ref: this.state.referencia,
      key: this.state.key,
      redirect: this.state.redirect,
      customer_id: this.props.customer,
    })

    if (result == 200) {
      this.props.reload()
      return this.props.close()
    }

  }

  updateLink = async (e) => {
    e.preventDefault()
    const result = await Api.updateLink('/admin/affiliateLink', {
      id: this.props.editKey,
      affiliate: this.state.afiliado,
      ref: this.state.referencia,
      key: this.state.key,
      redirect: this.state.redirect,
      customer_id: this.state.customer_id,
    })

    if (result == 200) {
      this.props.reload()
      return this.props.close()
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleChange(value) {
    this.setState({ afiliado: value.key }); // { key: "lucy", label: "Lucy (101)" }
  }

  componentDidMount() {
    this.props.affiliate != null ?
      this.setState({
        afiliado: this.props.affiliate[0].customer_id,
        referencia: this.props.affiliate[0].ref,
        key: this.props.affiliate[0].key,
        redirect: this.props.affiliate[0].redirect,
      })
      :
      null

      this.props.customer_data ? this.setState({
        afiliado: `${this.props.customer_data.user_data.email} / ${this.props.customer_data.customer_data.name}`
      })
      :
      null
  }

  render() {
    //console.log(this.props.affiliate)
    //console.log(this.props.customer)

    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };
    const formItemLayout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      className: 'ps-ant-formItem'
    }

    const Option = Select.Option;

    /*let data = this.props.customers.map(e => {
      let x = {}
      x['value'] = e
      x['label'] = e
      return x
    })

    const residences = data*/

    return (
      <Form onSubmit={this.props.edit ? this.updateLink : this.handleSubmit}> { /* {...formItemLayout}*/}
        <Form.Item
          label="Referencia"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'referencia')} value={this.state.referencia} />
        </Form.Item>
        <Form.Item
          label="Key"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'key')} value={this.state.key} required={true} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Redirect"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'redirect')} required={true} value={this.state.redirect}/>
        </Form.Item>
        <Form.Item
          label="Afiliado"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'afiliado')} required={true} value={this.props.affiliate != null ? this.state.afiliado : this.props.customer_data ? this.state.afiliado : this.state.afiliado} disabled={true}/>
          {/*<Select labelInValue defaultValue={this.props.customer != null ? { key: this.props.customer[0].user.email } : { key: 'Selecione um fotógrafo' }} style={{ width: "100%" }} onChange={(value) => this.handleChange(value)}>
            {
              residences.map((e, i) => {
                return (
                  <Option key={i} value={e.value}>{e.label}</Option>
                )
              })
            }
          </Select>*/}
        </Form.Item>
        <div style={{ width: "100%", height: 50, display: "flex", flexDirection: "row-reverse" }}>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" style={{}}>{this.props.edit ? "Salvar" : "Cadastrar"}</Button>
          </Form.Item>
        </div>
      </Form>

    )
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(CadForm);

export default WrappedRegistrationForm