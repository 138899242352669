import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from 'app/modules/Auth'
import Api from 'app/modules/Api'
import fullLogo from 'img/picsize.png'
import swal from 'sweetalert'
import 'custom/login.scss'
import LoginForm from './Form'

function isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

class Login extends Component {
  constructor (props, context) {
    const storedMessage = localStorage.getItem('successMessage')
    super(props, context)

    if (storedMessage) {
      localStorage.removeItem('successMessage')
    }

    this.state = {
      user: {email: '', password: ''},
      loading: false,
      errorStatus: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.recoverPassword = this.recoverPassword.bind(this)
  }

  componentDidMount () {
    document.body.classList.add('login')

    const {cat} = this.props.location.query
    const {push} = this.props.router
    if (!cat) {
      if (Auth.isAuthenticated) {
        return push('/dashboard')
      }
      return
    }
    this.setState({loading: true})
    Auth
    .tryLogin(false, false, cat)
    .then((res) => {
      if (res.success) {
        return window.location = './'
      }
      this.setState({loading: false})
    })
    .catch(() => this.setState({loading: false}))
  }

  handleChange (e) {
    const userState = this.state.user
    userState[e.target.name] = e.target.value
    this.setState({user: userState})
  }

  async recoverPassword (e) {
    e.preventDefault()
    const email = await swal({
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Digite o e-mail da conta',
          type: 'email'
        },
      },
    })
    if (!isValidEmail(email)) {
      return await swal('', 'Email inválido!', 'error')
    }
    await Api.post(`/account/requestRecover?email=${email}`)
    await swal('Pronto', 'Em breve você receberá um email com as instruções de recuperação de senha!', 'success')
  }

  processForm(event) {
    let {email, password} = this.state.user
    event.preventDefault()
    Auth
    .tryLogin(email, password)
    .then((res) => {
      if (res.success) {
        return window.location.reload()
      }
      this.setState({errorStatus: true})
    })
    .catch(() => this.setState({errorStatus: true}))
  }

  render() {
    if (this.state.loading) return (<div />)
    return (
      <div>
        <div className="logo">
          <a href="http://painel.picsize.com.br/">
            <img src={fullLogo}  alt="PICSIZE" />
          </a>
        </div>,
        <div className="content">
          <LoginForm
          onSubmit={this.processForm}
          errorStatus={this.state.errorStatus}
          handleChange={this.handleChange}
          recoverPassword={this.recoverPassword}
          />
        </div>
        <div className="copyright"> PICSIZE &copy; {(new Date()).getFullYear()}</div>
      </div>
    )
  }
}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Login
