import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import {
  Table, Input, InputNumber, Popconfirm, Form, Modal, Button
} from 'antd';

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'

import CadForm from './CadForm'

const data = [];

const FormItem = Form.Item;
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: false,
                      message: `Please Input ${title}!`,
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>
              ) : restProps.children}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data,
      editingKey: '',
      ModalText: 'Um email para criação de nova senha foi enviado ao seu cliente.',
      visible: false,
      modal2Visible: false,
      modalEdit: false,
      customer: [],
      affiliates: [],
      customersAllData: [],
      customer_data: false
    };
    this.columns = [
      {
        title: '#',
        dataIndex: 'id',
        width: '5%',
        editable: false,
      },
      {
        title: 'Afiliado',
        dataIndex: 'afiliado',
        width: '20%',
        editable: false,
      },
      {
        title: 'Key',
        dataIndex: 'keyUser',
        width: '20%',
        editable: false,
      },
      {
        title: 'Referência',
        dataIndex: 'ref',
        width: '20%',
        editable: false,
      },
      {
        title: 'Redirect',
        dataIndex: 'redirect',
        width: '20%',
        editable: false,
      },

      {
        title: 'Operações',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <div>
              <a disabled={editingKey !== ''} onClick={() => this.edit(record)}>Editar</a>
              <a disabled={editingKey !== ''} style={{ marginLeft: 15 }} onClick={() => this.ocult(record)}>Ocultar</a>
            </div>
          );
        },
      },
    ];
  }

  async ocult(record) {
    await Api.ocult(`/admin/affiliateLink/delet`, { id: record.id })
    this.getData()
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  handleOk() {
    this.setState({
      visible: false,
    });
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
        //console.log(newData[index].email)
        this.update(newData[index])
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  async update(newData) {
    try {
      await Api.put(newData, '/admin/affiliateLink')
    } catch (e) {
      console.log(e)
    }
  }

  edit(record) {
    this.setState({ editingKey: record, modal2Visible: true, modalEdit: true });
  }

  async componentDidMount() {
    const { id } = this.props.params
    let affiliate = id != null ? await Api.get(`/admin/customer/${id}`) : null
    this.setState({ customer: affiliate })
    this.getData()
    if (this.props.params.id) {
      const customer_data = await Api.get(`/admin/customer/${this.props.params.id}`)
      if (customer_data) {
        this.setState({ customer_data })
      }
    }
  }

  async getData() {
    try {
      const affiliates = await Api.get('/admin/affiliateLink')
      const notOcult = affiliates.filter(e => {
        if (e.deleted == true) return false
        return e
      })
      this.setState({
        affiliates: notOcult.sort(function (a, b) {
          return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
        })
      })
      this.setState({
        data: notOcult.map(e => {
          return {
            key: e.id,
            id: e.id,
            afiliado: e.customer_id,
            keyUser: e.key,
            ref: e.ref,
            redirect: e.redirect
          }
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
    this.cancel();
  }


  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    //console.log(this.state.customersAllData)

    return (
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="caption">
            <i className="fa fa-folder-open font-dark" />
            <span className="caption-subject font-dark sbold uppercase">
              Links de afiliados
                </span>
          </div>
          <div className="tools">
            <div className="actions">
              {this.props.params.id ?
                <span className="btn btn-circle btn-primary green" onClick={() => this.setModal2Visible(true)}>
                  <i onClick={() => this.setModal2Visible(true)} className="fa fa-plus"></i> Novo Link
                </span>
                : null}
            </div>
          </div>
        </div>
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered={false}
            dataSource={this.state.data || []}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
            }}
          />

          {
            this.state.visible ?
              <Modal
                title="Enviado"
                visible={this.state.visible}
                confirmLoading={this.state.confirmLoading}
                footer={<Button type="primary" onClick={() => this.handleOk()}>OK</Button>}
                closable={false}
                maskClosable={false}
              >
                <p>{this.state.ModalText}</p>
              </Modal>
              :
              null
          }

          {
            this.state.modal2Visible ?
              <Modal
                title="Cadastrar novo link"
                centered
                visible={this.state.modal2Visible}
                onOk={() => this.setModal2Visible(false)}
                onCancel={() => this.setModal2Visible(false)}
                footer={null}
                maskClosable={false}
                customer={this.props.params.id}
              >
                <CadForm close={() => this.setModal2Visible(false)}
                  customers={this.state.customers}
                  reload={() => this.getData()}
                  customer={this.props.params.id}
                  customer_data={this.state.customer_data || false} />
              </Modal>
              :
              null
          }

          {
            this.state.editingKey !== '' ?
              <Modal
                title={this.state.modalEdit ? "Editar Link" : "Cadastrar novo link"}
                centered
                visible={this.state.modal2Visible}
                onOk={() => this.setModal2Visible(false)}
                onCancel={() => this.setModal2Visible(false)}
                footer={null}
                edit={this.state.modalEdit}
                maskClosable={false}
              >
                <CadForm
                  close={() => this.setModal2Visible(false)} customers={this.state.customers}
                  reload={() => this.getData()}
                  affiliate={this.state.modalEdit ? this.state.affiliates.filter(e => e.id == this.state.editingKey.key) : null}
                  edit={this.state.modalEdit}
                  editKey={this.state.editingKey.id}
                />
              </Modal>
              :
              null
          }

        </EditableContext.Provider>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable