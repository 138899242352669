import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'

import Loading from 'app/widgets/Loading'
import CustomerListWidget from './Widget'
import Helpers from 'app/layouts/Main/Helpers'

class CustomerList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: false,
      listData: false,
      themes: false
    }

    this.getData = this.getData.bind(this)
    this.searchOnList = this.searchOnList.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const data = await Api.get('/admin/customer')
    const themes = await Api.get('/theme/info')
    if (data) {
      return this.setState({
        data,
        listData: data,
        themes: [
          ...themes
        ]
      })
    }
  }

  async searchOnList (e) {
    const word = (e.target.value || '').toLowerCase()
    this.setState({
      listData: this.state.data.filter(filterList)
    })

    function filterList (customer) {
      customer.user = customer.user || {}
      if (parseInt(word)){
        return (customer.id || '') === parseInt(word)
      } else {
        return customer.name.toLowerCase().includes(word)
        || (customer.url || '').toLowerCase().includes(word)
        || ((customer.user.name || '').toLowerCase().includes(word)
        || (customer.display_name || '').toLowerCase().includes(word)
        || (customer.display_email || '').toLowerCase().includes(word)
        || (customer.user.email || '').toLowerCase().includes(word)
        || (customer.whatsapp_number || '').toLowerCase().includes(word)
        || ('#' + customer.id || '').toString() === word)
      }
    }
  }

  render () {
    const data = this.state.data
    return (
      <div className="row">
        {
          !data ?
          <Loading /> :
          <div>
            <div className="col-md-12">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">
                    <span className="caption-subject font-dark sbold uppercase">
                      Lista de Clientes
                    </span>
                  </div>
                  <div className="tools" style={{ padding: '0px' }}>
                   <div className="input-icon right">
                     <i className="icon-magnifier"></i>
                     <input className="form-control" onChange={this.searchOnList} placeholder="Pesquisar por cliente" type="text" />
                    </div>
                  </div>
                </div>
                <div className="portlet-body form">
                  <CustomerListWidget
                    loginAs={Auth.loginAs}
                    data={this.state.listData}
                    themes={this.state.themes}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

CustomerList.contextTypes = {
  router: PropTypes.object.isRequired
}

export default CustomerList
