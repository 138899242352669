import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from 'app/modules/Auth'
import 'custom/error.scss'

class Error extends Component {
  componentDidMount() {
    document.body.classList.add('page-404-full-page')
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 page-404">
          <div className="number font-red"> 404 </div>
          <div className="details">
            <h3>
            Ops... Algo deu errado
            </h3>
            <p> Não encontramos a página que você estava procurando.
              <br />
              <a href="/">Clique aqui</a> para voltar à página inicial.
            </p>
              {/* /input-group */}
          </div>
        </div>
      </div>
    )
  }
}

Error.contextTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Error
