import React from 'react'
import format from 'date-fns/format'
import compare from 'date-fns/compare_asc'
import { Table, Tag, Button } from 'antd'

const { Column, ColumnGroup } = Table

export default function PlansListWidget(props) {

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: id => <span>#{id}</span>,
    },
    {
      title: 'Categoria',
      key: 'category',
      render: plan => <span>{plan.category}</span>,
    },
    {
      title: 'Nome',
      key: 'name',
      render: plan => <span>{plan.name}</span>,
    },
    {
      title: 'Limites',
      key: 'limits',
      render: plan => <><Button onClick={() => {props.onSelect(plan)}}>Editar</Button>  <Button onClick={() => {props.onView(plan)}}>Ver</Button></>
    },
    {
      title: 'Deletado',
      key: 'deleted',
      render: plan => <span>{plan.deletedAt}</span>,
    }
  ]
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey="id"
      scroll={{ x: 250 }}
      size="middle"
      pagination={{
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`, 
        pageSize: 15
      }}
    />
  )
}