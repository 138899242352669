import React, { Component } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Api from 'app/modules/Api'

function ReportListWidget(props) {
  return (
    <div className="portlet light">
      <div className="portlet-title">
        <div className="caption">
          <span className="caption-subject font-dark sbold">
            {props.data.title}
          </span>
          <span className="caption-subject font-dark-grey sbold"> | {format(props.data.created_at, 'DD/MM/YYYY')}</span>
        </div>
        <div className="tools" style={{ padding: '0px' }}>
          <a href={`${Api.url}/admin/report/${props.data.id}?export=1`} target='_blank'>
            <button
              type="button"
              className="btn green ladda-button" style={{ marginRight: '5px' }} data-style="slide-right"
            >
              Exportar relatório
           </button>
          </a>
        </div>
      </div>
      <div className="portlet-body form">
        <ReactTable
          columns={
            props.data.fields.map((field) => {
              return {
                Header: field.value,
                id: field.key,
                accessor: d => d[field.key]
              }
            })}
          data={props.data.contents}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </div>
  )
}

export default ReportListWidget
