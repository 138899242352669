import React from 'react'
import PropTypes from 'prop-types'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/mode/css'
import 'brace/mode/javascript'
import 'brace/theme/github'
import 'brace/theme/tomorrow'

import FormContext from '../context'

function InputAceEditor (props) {
  return (
    <FormContext.Consumer>
    {(form) => (
      <div className={props.className}>
         <AceEditor
           mode={props.mode}
           theme="github"
           name={props.name}
           width='100%'
           onChange={(value) => form.onChange({target: {name: props.name, value}})}
           editorProps={{$blockScrolling: true}}
           fontSize={14}
           showPrintMargin={true}
           showGutter={true}
           highlightActiveLine={true}
           value={form.inputs[props.name]}
           setOptions={{
             showLineNumbers: true,
             tabSize: 2,
             minLines: 15
           }}/>
      </div>
    )}
  </FormContext.Consumer>
  )
}

InputAceEditor.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  mode: PropTypes.string,
}

export default InputAceEditor
