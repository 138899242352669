import React from 'react'
import PropTypes from 'prop-types'

import FormContext from '../context'

function LabelHint (props) {
  return (
    <FormContext.Consumer>
      {(form) => {
        return (
          <i
          className="fa fa-question-circle popovers"
          data-container={`#${form.id}`}
          data-trigger={props.trigger}
          data-placement={props.placement}
          data-content={props.children}
          data-original-title={props.title}
          aria-hidden="true"
          />
        )
      }}
    </FormContext.Consumer>
  )
}

LabelHint.propTypes = {
  children: PropTypes.string.isRequired,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string
}

LabelHint.defaultProps = {
  title: '',
  trigger: 'hover',
  placement: 'right'
}

export default LabelHint
