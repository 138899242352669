/* eslint-disable brace-style */
/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as moment from 'moment'
import { Form, DatePicker } from 'antd'

import Api from '../../modules/Api'
import GalleryItem from './GalleryItem'

import './Galleries.scss'

export default class Galleries extends Component {
  lastCursors = []
  cursor = null
  plans = new Map()

  constructor() {
    super(...arguments)

    this.state = {
      date: moment(),
      galleries: [],
    }
  }

  async componentDidMount() {
    await this.getPlans()
    this.getData()
  }

  async getData() {
    try {
      let params = `date=${this.state.date.format('Y/MM/DD')}`
      if (this.cursor) {
        params += `&cursor=${this.cursor}`
      }
      const res = await Api.get(`/admin/galleries?${params}`)
      if (!res || !Array.isArray(res.galleries)) {
        throw res
      }
      for (const i of res.galleries) if (i.customer) {
        try {
          const planId = i.subscription.plan_ids[0]
          if (this.plans.has(planId)) {
            i.customer.planName = this.plans.get(planId).name
          }
        }
        catch (ex) {
          continue
        }
      }
      this.setState({ galleries: res.galleries })
    }
    catch (ex) {
      console.warn(ex)
    }
  }

  async getPlans() {
    try {
      const plans = await Api.get('/admin/plans')
      if (!Array.isArray(plans)) {
        return
      }
      const map = new Map()
      for (const i of plans) {
        map.set(i.id, i)
      }
      this.plans = map
    }
    catch (ex) {
      console.error(ex)
    }
  }

  prev() {
    this.setState({ date: this.state.date.add(-1, 'day') }, () => this.getData())
  }

  next() {
    this.setState({ date: this.state.date.add(1, 'day') }, () => this.getData())
  }

  goDate(date) {
    this.setState({ date }, () => this.getData())
  }

  render() {
    return <div className='acp-galleries-list'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Form.Item label="Usuários e Galerias criados em">
          <DatePicker
            format="DD-MM-YYYY"
            value={this.state.date}
            onChange={(m) => this.goDate(m)}
          />
        </Form.Item>
        <button className='btn btn-default' onClick={this.prev.bind(this)}>Prev</button>
        <button className='btn btn-default' onClick={this.next.bind(this)}>Next</button>
      </div>



      <div className='gallery-list-header' onClick={open}>
        <div className='gallery-cover'>Capa</div>
        {/* <div className='col-sm-1'>ID</div> */}
        <div className='col-sm-2'>Titulo</div>
        <div className='col-sm-2'>Tipo</div>
        <div className='col-sm-1'>Fotos</div>
        <div className='col-sm-2'>Criada em</div>
        <div className='col-sm-2'>Cliente</div>
        <div className='col-sm-2'>Plano</div>
        <div className='col-sm-2'>Cliente (Registro)</div>
      </div>
      <div className='gallery-list'>
        {this.state.galleries.map((i, id) => <GalleryItem key={id} data={i} />)}
      </div>
    </div>
  }
}

Galleries.propTypes = {
  location: PropTypes.any,
  router: PropTypes.any,
}
