import React from 'react'
import {Link} from 'react-router'

function LoginForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <Alert active={props.errorStatus}/>
      <div className="form-group email optional user_email">
        <label
          className="control-label email optional">Email</label>
        <input
          className="form-control string email optional" type="text" onChange={props.handleChange} style={{textTransform: "lowercase"}} name="email"/>
      </div>
      <div className="form-group password optional user_password">
        <label
          className="control-label password optional"
          htmlFor="user_password">Senha</label>
        <input
          className="form-control password optional" type="password" onChange={props.handleChange} name="password"/>
      </div>
      <div className="form-actions">
        <div className="row">
          <div className="col-md-12">
            <button
              type="submit"
              name="commit"
              className="btn signin uppercase pull-right">
              Entrar
            </button>
          </div>
          </div>
        </div>
      </form>
  )
}

function Alert(props) {
  if (!props.active) return null
  return (
    <div className="alert alert-danger animated fadeIn" >
      E-mail ou senha inválidos.
    </div>
  )
}

export default LoginForm
