import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import swal from 'sweetalert'
import format from 'date-fns/format'

import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'

import Loading from 'app/widgets/Loading'
import ReportListWidget from './Widget'

class ReportList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: false,
      listData: false
    }

    this.getData = this.getData.bind(this)
    this.viewReport = this.viewReport.bind(this)
    this.generateReport = this.generateReport.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate () {
  }

  async getData () {
    const data = await Api.get('/admin/report')
    if (data) {
      return this.setState({ data, listData: data })
    }
  }

  async viewReport (id) {
    this.props.router.push(`/report/${id}/view`)
  }

  async generateReport () {
    await Api.get('/admin/report/generate/customers_usage')
    await swal({
      title: 'Pronto!',
      text: 'O relatório está sendo processado e estará disponível dentro de alguns minutos.',
      icon: 'success'
    })
  }

  render () {
    const data = this.state.data
    return (
      <div className="row">
        {
          !data ?
          <Loading /> :
          <div className="col-md-12">
            <ReportListWidget
              data={this.state.listData}
              viewReport={this.viewReport}
              generateReport={this.generateReport}
            />
          </div>
        }
      </div>
    )
  }
}

ReportList.contextTypes = {
  router: PropTypes.object.isRequired
}

export default ReportList
