import React, { Component } from 'react'

import Api from 'app/modules/Api'
import Loading from 'app/widgets/Loading'
import ReportDataWidget from './ReportData'

export default class CustomerUsageView extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const { id } = this.props.params
        const data = await Api.get(`/admin/consolid-report/${id}`)
        if (data) {
            this.setState({
                data
            })
        }
    }

    render() {
        return (
            <div className='ps-adm-customer-usage-view row'>
                {this.state.data ? this.renderData() : Loading()}
            </div>
        )
    }

    renderData() {
        return (
            <div className="col-md-12">
                <ReportDataWidget data={this.state.data} />
            </div>
        )
    }

}
