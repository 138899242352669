import React from 'react'
import PropTypes from 'prop-types'

import {
  Select,
  Button,
  Form,
  Row,
  Col,
  Switch,
  DatePicker
} from 'antd'

import moment from 'moment'

const formItemStyles = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
  className: 'ps-ant-formItem'
}

class GalleryPlanForm extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.onChange = this.onChange.bind(this)

    this.plansByCategory = {
      site: this.props.plans.filter((plan) => plan.code.startsWith('site_')),
      col: this.props.plans.filter((plan) => plan.code.startsWith('col_')),
      sel: this.props.plans.filter((plan) => plan.code.startsWith('sel_')),
      album: this.props.plans.filter((plan) => plan.code.startsWith('album_')),
    }

    const { plan_ids, expiration_date } = this.props.customerPlanData

    let site_plan_id, col_plan_id, sel_plan_id, album_plan_id
    if (plan_ids) {
      const planIdsArrays = {
        site: this.plansByCategory.site.map((plan) => plan.id),
        col: this.plansByCategory.col.map((plan) => plan.id),
        sel: this.plansByCategory.sel.map((plan) => plan.id),
        album: this.plansByCategory.album.map((plan) => plan.id)
      }

      site_plan_id = plan_ids.find((value) => planIdsArrays.site.indexOf(value) > -1)
      col_plan_id = plan_ids.find((value) => planIdsArrays.col.indexOf(value) > -1)
      sel_plan_id = plan_ids.find((value) => planIdsArrays.sel.indexOf(value) > -1)
      album_plan_id = plan_ids.find((value) => planIdsArrays.album.indexOf(value) > -1)
    }

    this.state = {
      ...this.props.customerPlanData,
      expiration_date: expiration_date && moment(expiration_date),
      site_plan_id,
      col_plan_id,
      sel_plan_id,
      album_plan_id,
      submittingSaveAndPublish: false,
      submittingSave: false,
    }
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({ [key]: value })
  }

  async submit(event, notOpenWindow, submitOption) {
    event.preventDefault()

    submitOption == 'ONLY_SAVE'
      ? this.setState({ submittingSave: true })
      : this.setState({ submittingSaveAndPublish: true })

    let plan_ids
    if (this.state.col_plan_id) {
      plan_ids = [this.state.col_plan_id, this.state.site_plan_id].filter((plan) => !!plan)
      this.setState({
        sel_plan_id: null,
        album_plan_id: null
      })
    } else {
      plan_ids = [this.state.site_plan_id, this.state.sel_plan_id, this.state.album_plan_id].filter((plan) => !!plan
      )
    }

    this
      .props
      .saveData(
        {
          plan_ids,
          active: this.state.active,
          trial: this.state.trial,
          expiration_date: this.state.expiration_date && this.state.expiration_date.toISOString()
        },
        notOpenWindow
      )
      .then(() => {
        this.setState({
          submittingSaveAndPublish: false,
          submittingSave: false
        })
      })
  }

  render() {
    return (
      <Form layout="horizontal" onSubmit={(event) => event.preventDefault()}>
        <Form.Item label="Ativo/Congelado" {...formItemStyles}>
          <Switch
            checked={this.state.active}
            onChange={(active) => this.onChange(active, 'active')}
            checkedChildren="Ativo"
            unCheckedChildren="Congelado"
          />
        </Form.Item>

        <Form.Item label="Site" {...formItemStyles}>
          <Select
            name='site_plan_id'
            value={this.state.site_plan_id}
            onChange={(value) => this.onChange(value, 'site_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>
              Nenhum
            </Select.Option>

            {this.plansByCategory.site.map((plan) => (
              <Select.Option value={+plan.id} key={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Gallery Pro" {...formItemStyles}>
          <Select
            name='col_plan_id'
            value={this.state.col_plan_id}
            onChange={(value) => this.onChange(value, 'col_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option key='ps-plan-default' value={undefined}>
              Nenhum
            </Select.Option>

            {this.plansByCategory.col.map((plan) => (
              <Select.Option value={+plan.id} key={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Galerias e Seleção" {...formItemStyles}>
          <Select
            name='sel_plan_id'
            value={this.state.sel_plan_id}
            onChange={(value) => this.onChange(value, 'sel_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>
              Nenhum
            </Select.Option>

            {this.plansByCategory.sel.map((plan) => (
              <Select.Option value={+plan.id} key={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Prova de Álbuns" {...formItemStyles}>
          <Select
            name='album_plan_id'
            value={this.state.album_plan_id}
            onChange={(value) => this.onChange(value, 'album_plan_id')}
            placeholder="Selecione um plano"
          >
            <Select.Option value={undefined}>
              Nenhum
            </Select.Option>

            {this.plansByCategory.album.map((plan) => (
              <Select.Option value={+plan.id} key={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Trial" {...formItemStyles}>
          <Switch
            checked={this.state.trial}
            onChange={(trial) => this.onChange(trial, 'trial')}
          />
        </Form.Item>

        {!!this.state.trial && (
          <Form.Item label="Data de expiração" {...formItemStyles}>
            <DatePicker
              format="DD-MM-YYYY"
              value={this.state.expiration_date}
              onChange={(expiration_moment) => this.onChange(expiration_moment, 'expiration_date')}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ span: 21, offset: 0 }}>
          <Row>
            <Col
              span={24}
              style={{ textAlign: 'right', width: '100%', minWidth: 320 }}
            >
              <Button
                loading={this.state.submittingSaveAndPublish}
                style={{ width: '150px' }}
                onClick={(event) => this.submit(event, false)}
              >
                Salvar e Publicar
              </Button>

              <Button
                loading={this.state.submittingSave}
                type="primary"
                onClick={(event) => this.submit(event, true, 'ONLY_SAVE')}
                style={{ width: '150px', marginLeft: 8 }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }
}

GalleryPlanForm.propTypes = {
  saveData: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  customerPlanData: PropTypes.object.isRequired
}


export default GalleryPlanForm
