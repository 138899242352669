/* eslint-disable no-empty-pattern */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import GalleryDialog from './GalleryDialog'

export default function GalleryItem({ data }) {

  function open() {
    GalleryDialog.open({ data })
  }

  if (!data || !data.id) {
    return null
  }

  return (
    <div className='gallery-item' onClick={open}>
      <div className='gallery-cover'>{data.coverUrl && <img src={data.coverUrl} />}</div>
      {/* <div className='col-sm-1'>{data.id}</div> */}
      <div className='col-sm-2'>{data.title}</div>
      <div className='col-sm-2'>{data.collection_type === 'gallery' ? 'Entrega' : 'Seleção'}</div>
      <div className='col-sm-1'>{data.pictures_len}</div>
      <div className='col-sm-2'>{data.created_at && moment(data.created_at).fromNow() || ''}</div>
      <div className='col-sm-2'>{data.customer && data.customer.name || ''}</div>
      <div className='col-sm-2'>{data.customer && data.customer.planName}</div>
      <div className='col-sm-2'>{data.customer && moment(data.customer.created_at).fromNow() || ''}</div>
    </div>
  )

}

GalleryItem.propTypes = {
  data: PropTypes.any
}
