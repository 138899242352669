import React, {Component} from 'react'
import PropTypes from 'prop-types'

import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import {Dropdown, Menu, Icon} from 'antd'

import Auth from '~/modules/Auth'
import logoPICSIZE from 'img/logo-picsize.png'

import TopMenu from './TopMenu'

class TopHeader extends Component {
  render() {
    const menu = (
      <Menu>
        <Menu.Item key="0" className="ant-dropdown-menu-item-active">
          <a>
            <i className="fa fa-check-square-o" /> Seleção
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <a onClick={() => Auth.crossLogin('site')}>
            <i className="fa fa-tv" /> Site
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <div className="page-header-inner">
        <a
          href="javascript:;"
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse">
          <Icon type="bars" />
        </a>
        <div style={{display: 'inline-block', float: 'left'}}>
          <img src={logoPICSIZE} style={{ padding: '19px 12px', width: '170px' }} />
        </div>
        <div className="page-actions">
          <Dropdown overlay={menu} trigger={['click']} overlayStyle={{zIndex: 9999}}>
            <button type="button" className="btn btn-circle btn-outline red dropdown-toggle">
              <i className="fa fa-check-square-o" />
              &nbsp;
                <span className="hidden-sm hidden-xs">
                  Seleção&nbsp;
                </span>&nbsp;
                <i className="fa fa-angle-down" />
            </button>
          </Dropdown>
        </div>
        <div className="page-top">
          <TrialNotice />
          <TopMenu />
        </div>
      </div>
    )
  }
}

function TrialNotice () {
  const {subscription} = Auth.userData
  const style = {
    display: 'inline-block',
    marginTop: '17.5px',
    marginLeft: '10px',
    padding: '5px 10px'
  }
  if (!subscription || !subscription.trial || !subscription.expiration_date) return null
  if (subscription.expired) {
    return (
      <div className="alert alert-danger  " style={style}>
        <strong>Atenção!</strong> O seu período de testes expirou! <a href="https://www.picsize.com.br/contato">Clique aqui</a> para entrar em contato
      </div>
    )
  }
  return (
    <div className="alert alert-warning" style={style}>
      <strong>Atenção!</strong> O seu período de testes termina em
        <strong> {differenceInCalendarDays(subscription.expiration_date, new Date())} dias
        </strong>
    </div>
  )
}

TopHeader.propTypes = {
  children: PropTypes.element
}

export default TopHeader
