import React, { Component } from 'react'
import swal from 'sweetalert'

import Api from 'app/modules/Api'
import Loading from 'app/widgets/Loading'
import ReportListWidget from './ListWidget'

export default class CustomerUsageList extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            data: null,
            listData: null
        }
        this.generate = this.generate.bind(this)
        this.viewReport = this.viewReport.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const data = await Api.get('/admin/consolid-report')
        if (data) {
            return this.setState({ data, listData: data })
        }
    }

    async generate() {
        const res = await Api.get('/admin/consolid-report/generate/customers_usage')
        if (!res || !res.id) {
            return swal({
                title: 'Ops!',
                text: 'O comando para gerar o relatório falhou.',
                icon: 'warning'
            })
        }
        await swal({
            title: 'Pronto!',
            text: 'O relatório está sendo processado e estará disponível dentro de alguns minutos.',
            icon: 'success'
        })
        this.getData()
    }

    async viewReport(id) {
        this.props.router.push(`/consolid/usage/${id}/view`)
    }

    render() {
        return (
            <div className='ps-adm-customer-usage-page row'>
                {this.state.data ? this.renderList() : Loading()}
            </div>
        )
    }

    renderList() {
        return (
            <div className="col-md-12">
                <ReportListWidget
                    data={this.state.listData}
                    viewReport={this.viewReport}
                    generateReport={this.generate}
                />
            </div>
        )
    }

}
