import React, {Component} from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import compare from 'date-fns/compare_asc'
import {Table, Divider, Tag, Button} from 'antd'
import Api from '~/modules/Api'

const {Column, ColumnGroup} = Table

class ButtonNewPass extends Component {
  static propTypes = {
    loginAs: PropTypes.any
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      loading: false
    }
  }

  onClick () {
    this.setState({loading: true})
    this
    .props
    .loginAs()
    .then(() => {
      this.setState({loading: false})
    })
  }

  render () {
    return (
      <Button icon="sync" style={{marginLeft: 5}} onClick={this.onClick.bind(this)} loading={this.state.loading}>
        Nova Senha
      </Button>
    )
  }
}

class ButtonToLoad extends Component {
  static propTypes = {
    loginAs: PropTypes.any
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      loading: false
    }
  }

  onClick () {
    this.setState({loading: true})
    this
    .props
    .loginAs()
    .then(() => {
      this.setState({loading: false})
    })
  }

  render () {
    return (
      <Button icon="select" onClick={this.onClick.bind(this)} loading={this.state.loading}>
        Logar
      </Button>
    )
  }
}

function CustomerListWidget (props) {

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: id => <span>#{id}</span>,
  }, {
    title: 'Nome',
    key: 'name',
    render: customer => (
      <>
        <a href={`/customer/${+customer.id}/edit`}>{customer.name}</a>
        {customer.display_name && (<>
        <br />
        <small>Nome de exibição: {customer.display_name}</small>
        </>)}
      </>
    ),
  }, {
    title: 'Status',
    dataIndex: 'subscription',
    key: 'subscription-status',
    filters: [
      {
        text: 'Sem assinatura',
        value: 'no_subscription',
      },
      {
        text: 'Trial',
        value: 'trial',
      }, {
        text: 'Expirado',
        value: 'expirado',
      }, {
        text: 'Ativo',
        value: 'ativo',
      }, {
        text: 'Inativo',
        value: 'inativo',
      }
    ],
    onFilter: (value, c) => {
      return (
        (c.subscription.no_subscription && value === 'no_subscription') ||
        (!c.subscription.active && value === 'inativo') ||
        (c.subscription.active && (!c.subscription.trial || !c.subscription.expiration_date) && value === 'ativo') ||
        (c.subscription.trial && c.subscription.expiration_date && (compare(new Date(), c.subscription.expiration_date) < 1) && value === 'trial') ||
        (c.subscription.trial && c.subscription.expiration_date && !(compare(new Date(), c.subscription.expiration_date) < 1) && value === 'expirado')
      )
    },
    render: function SubsStatus (subscription) {
      if (subscription.no_subscription) return <Tag color="grey">Sem assinatura</Tag>
      if (!subscription.active) return <Tag color="red">Inativo</Tag>
      if (!subscription.trial || !subscription.expiration_date) return <Tag color="blue">Ativo</Tag>
      return (
        <span>
          {(compare(new Date(), subscription.expiration_date) < 1) ? (
            <Tag color="orange">Trial</Tag>
          ) : (
            <Tag color="gray">Expirado</Tag>
          )}
          <i key="trial-expiration">
            {subscription.expiration_date && ` (${format(subscription.expiration_date, 'DD/MM/YYYY')})`}
          </i>
        </span>
      )
    }
  }, {
    title: 'Planos',
    key: 'subscription-plans',
    dataIndex: 'subscription.plans',
    render: (plans, customer) => {
      if (customer.subscription.no_subscription) {
        return (
          <div />
        )
      } else {
        return (
          <span>
            {plans.map(tag => <Tag key={tag}>{tag}</Tag>)}
          </span>
        )
      }
    },
  }, {
    title: 'E-mail',
    dataIndex: 'user.email',
    key: 'email',
    render: (user_email, customer) => (
      <>
        <span><b>{user_email}</b></span>
        {customer.display_email && (<>
        <br />
        <small>Email de exibição: {customer.display_email}</small>
        </>)}
      </>
    )
  }, {
    title: 'Data de cadastro',
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => <span>{format(created_at, 'DD/MM/YYYY HH:mm:ss')}</span>,
  }, {
    title: 'Ações',
    key: 'actions',
    fixed: 'right',
    width: 270,
    render: function renderActions (c) {
      const url_tail  = !!c.user && !!c.user.authentication_token && c.user.authentication_token
      return (
        <div style={{displa: 'inline-block'}}>
          <Button shape="circle" type="primary" icon="edit" href={`/customer/${+c.id}/edit/old/${url_tail}`} target='_blank' />
          <Button shape="circle" icon="setting" style={{marginLeft: '5px'}} href={'/customer/'+c.id+'/internal_variables'} target='_blank' />
          <Button shape="circle" icon="global" style={{margin: '0 5px'}} href={c.url} target='_blank' />
          <ButtonToLoad loginAs={() => props.loginAs(c.id)} />
        </div>
      )
    }
  }]
 return (
   <Table
     columns={columns}
     dataSource={props.data}
     rowKey="id"
     scroll={{x: 250}}
     size="middle"
     pagination={{
       showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`
     }}
    />
 )
}

export default CustomerListWidget
