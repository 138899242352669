import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './context'


function parseValues (inputs) {
  const parsed = {}
  for (const inputName in inputs) {
    if (inputs[inputName] === null) {
      parsed[inputName] = ''
      continue
    }
    if (typeof inputs[inputName] === 'object') {
      parsed[inputName] = JSON.stringify(inputs[inputName])
      continue
    }
    parsed[inputName] = inputs[inputName]
  }
  return parsed
}

class Form extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      inputs: parseValues(this.props.inputs),
      id: this.props.id,
      onChange: this.handleChange.bind(this),
      onSubmit: this.onSubmit.bind(this),
    }
  }

  handleChange (e) {
    const inputsState = this.state.inputs
    inputsState[e.target.name] = e.target.value
    this.setState({ inputs: inputsState })
  }

  componentDidMount () {
  }

  onSubmit (e) {
    if (e && e.preventDefault) e.preventDefault()
    return this.props.onSubmit(this.state.inputs)
  }

  render () {
    return (
      <FormContext.Provider value={this.state}>
        <form id={this.props.id} className={`form-horizontal ${this.props.className}`} role="form" onSubmit={this.onSubmit.bind(this)}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  children: PropTypes.any,
  inputs: PropTypes.object,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
}

Form.defaultProps = {
  inputs: {},
  className: ''
}

export default Form
