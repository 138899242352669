import Auth from './Auth'
import $ from 'jquery/src/core'
import 'jquery/src/ajax'
import 'jquery/src/ajax/xhr'

const apiUrl = process.env.API_URL

function Api() {
  const xhrPool = []

  return {
    updatePlan, get, post, del, upload, passRecovery, abortUploads, ocult, newLink, updateLink, url: apiUrl
  }

  function updatePlan(path, data){
    return fetch(`${apiUrl}${path}`, {
      method: "PUT", 
      headers: { 'Content-Type': 'application/json' }, 
      body: JSON.stringify(data), 
      credentials: 'include', 
      crossDomain: true,
    }).then(e => {
      return e.status
    }).catch(err => console.log(err))
  }

  function abortUploads() {
    $.each(xhrPool, (idx, jqXHR) => {
      jqXHR.abort()
    })
  }

  function ocult(path, data) {
    return fetch(`${apiUrl}${path}`, {
      method: "PUT", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
        id: data.id,
      }), credentials: 'include', crossDomain:true,
    }).then(e => {
      return e.status
    }).catch(err => console.log(err))
  }

  function get(path) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'GET',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: (error) => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function newLink(path, data) {
    return fetch(`${apiUrl}${path}`, {
      method: "POST", headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', }, body: JSON.stringify({
        created_at: data.created_at,
        affiliate: data.affiliate,
        ref: data.ref,
        key: data.key,
        redirect: data.redirect,
        customer_id: data.customer_id
      }), credentials: 'include'
    }).then(e => {
      return e.status
    }).catch(err => console.log(err))
  }

  function updateLink(path, data) {
    return fetch(`${apiUrl}${path}`, {
      method: "PUT", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
        id: data.id,
        affiliate: data.affiliate,
        ref: data.ref,
        key: data.key,
        redirect: data.redirect,
        customer_id: data.customer_id
      }), credentials: 'include', crossDomain:true,
    }).then(e => {
      return e.status
    }).catch(err => console.log(err))
  }

  function passRecovery(path) {
    return fetch(`${apiUrl}${path}`, { method: "POST" }).then(resp => {
      return resp.json().then(e => e)
    }).catch(err => err)
  }

  function post(path, data, json = false) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'POST',
        data,
        ...(json && {
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: (error) => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }


  function upload(path, params, files, onProgress) {
    const formData = new FormData()
    for (let file of files) {
      formData.append('images', file)
    }
    for (let param in params) {
      formData.append(param, params[param])
    }
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'POST',
        data: formData,
        crossDomain: true,
        beforeSend: (jqXHR, settings) => {
          xhrPool.push(jqXHR)
        },
        xhr: () => {
          const myXhr = $.ajaxSettings.xhr()
          if (myXhr.upload) {
            myXhr.upload.addEventListener('progress', (e) => {
              if (!e.lengthComputable || !onProgress) return false
              const max = e.total
              const current = e.loaded
              return onProgress(current, current / max)
            }, false)
          }
          return myXhr
        },
        xhrFields: {
          withCredentials: true
        },
        contentType: false,
        processData: false,
        success: resolve,
        error: (error) => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function del(path) {
    return new Promise((resolve) => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'DELETE',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: (error) => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }
}

export default Api()