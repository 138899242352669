import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'antd'

import Form from '~/widgets/Form'

function CustomerConfigForm (props) {
  return (
    <Form id='reviewer-form' {...props}>
      <Form.Group>
        <Form.Label className="col-md-2">
          Qualidade do envio de imagens (%)
        </Form.Label>
        <Form.Input type="text" name="upload_quality" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Token Facebook API
        </Form.Label>
        <Form.Input type="text" name="token_facebook" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Token Enviar Formulários
        </Form.Label>
        <Form.Input type="text" name="token_enviar_formularios" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Google Validation
        </Form.Label>
        <Form.Input type="text" name="google_validation" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Google Analytics
        </Form.Label>
        <Form.Input type="text" name="google_analytics" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Code In
        </Form.Label>
        <Form.Input.AceEditor mode="json" name="code_in" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          Outras variáveis
        </Form.Label>
        <Form.Input.AceEditor mode="json" name="custom_variables" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          JS Customizado
        </Form.Label>
        <Form.Input.AceEditor mode="javascript" name="custom_js" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="col-md-2">
          CSS Customizado
        </Form.Label>
        <Form.Input.AceEditor mode="css" name="custom_css" className="col-md-8" />
      </Form.Group>
      <Form.Group>
        <div className="col-md-12">
        <Form.Consumer>
            {(form) => (
              <Form.Button
              onClick={form.onSubmit}
              style={{ float: 'right', display: 'inline-block', marginLeft: '5px' }}
              className="green ladda-button"
              data-style="slide-right">
                <span className="ladda-label">
                <i className="fa fa-check" /> Salvar
                </span>
              </Form.Button>
            )}
        </Form.Consumer>
        <Form.Consumer>
            {(form) => (
              <Form.Button
                onClick={(e) => {
                  const win = window.open(props.inputs.url, '_blank')
                  win.focus()
                  form.onSubmit(e)
                }}
                style={{ float: 'right', display: 'inline-block' }}
                className="green ladda-button"
                data-style="slide-right">
                <span className="ladda-label">
                  Salvar e publicar
                </span>
              </Form.Button>
            )}
        </Form.Consumer>
        </div>
      </Form.Group>
    </Form>
  )
}



CustomerConfigForm.propTypes = {
  inputs: PropTypes.object,
  onChangeInput: PropTypes.func,
  onSubmit: PropTypes.func
}

export default CustomerConfigForm
