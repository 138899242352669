import React, {Component} from "react"
import PropTypes from "prop-types"

function PanelBox (props) {
  return (
    <div className="portlet light ">
      <div className="portlet-title tabbable-line">
        <div className="caption caption-md">
          <i className="icon-globe theme-font hide" />
          <span className="caption-subject font-green bold uppercase">
            {props.title}
          </span>
        </div>
      </div>
      <div className="portlet-body">{props.children}</div>
    </div>
  )
}

function Item (props) {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">{props.title}</div>
      <div className="panel-body">
        {props.children}
      </div>
    </div>
  )
}

PanelBox.Item = Item

export default PanelBox
